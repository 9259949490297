import React, { useState } from 'react'
import { Block, Button, Heading, Icon, Label, TextInput } from 'suomifi-ui-components'
import { RAKLupamaarays, RAKLupapaatos } from '../../api/rytj/rytj-api'
import { rytjCodes } from '../../api/rytj/rytj-codelist';
import guid from '../../utils/guid'; // TODO use uuid library
import CustomDropdown from '../common/CustomDropdown';
import SlateTextEditor from '../common/SlateTextEditor';
import DateInput from '../DateInput';

type Props = {
  permissionDecision: RAKLupapaatos
}

interface RAKLupamaaraysWithId extends RAKLupamaarays {
  id?: string;
}


const PermissionDecisionEdit = (props: Props) => {
  const permissionDecision = props.permissionDecision;

  const [lupamaaraykset, setLupamaaraykset] = useState<RAKLupamaaraysWithId[]>(permissionDecision.lupamaarays || []);


  return (
    <>

      <TextInput className='input--custom' name={"lupatunnus.tunnus"} defaultValue={permissionDecision?.lupatunnus?.tunnus} labelText="Lupatunnus" required></TextInput>
      <TextInput className='input--custom' name={"lupatunnus.tunnuksenantaja"} defaultValue={permissionDecision?.lupatunnus?.tunnuksenantaja} labelText="Lupatunnuksen antaja"></TextInput>

      <TextInput className='input--custom' name={"sijoittamis-toteuttamislupa"} defaultValue={permissionDecision['sijoittamis-toteuttamislupa']} labelText="Sijoittamis-/toteuttamislupa"></TextInput>
      <DateInput className='input--custom' name={"rakennustyotaloitettavaviimeistaan"} defaultValue={permissionDecision?.rakennustyotaloitettavaviimeistaan} labelText="Rakennustyöt aloitettava viimeistään" required></DateInput>
      <DateInput className='input--custom' name={"rakennustyotvalmistuttavaviimeistaan"} defaultValue={permissionDecision?.rakennustyotvalmistuttavaviimeistaan} labelText="Rakennustyöt valmistuttava viimeistään" required></DateInput>

      {/* TODO tee yleisesti käytettävä komponentti -- jos jää aikaa */}
      <Label className='input--custom'>Lupamääräykset</Label>
      {/* <Box ml={2}> */}
      {/* <ArrayEdit name="alueidenkayttopaatoksenlaatija"> */}
      {lupamaaraykset.map((lupamaarays, i) =>
        <div key={lupamaarays.id || i} >
          {/* <TextInput name="nimike" defaultValue={laatija.nimike} labelText="Nimike"></TextInput> */}
          <Block variant='span' className='block--custom-with-borders'>
            <Button variant='secondaryNoBorder' style={{ position: "absolute", top: 0, right: 0 }} onClick={() => { setLupamaaraykset([...lupamaaraykset.slice(0, i), ...lupamaaraykset.slice(i + 1, lupamaaraykset.length)]) }}>
              <Icon icon='close'></Icon>
            </Button>

            {/* TODO käytä slate editoria? */}
            <SlateTextEditor name={"lupamaarays[" + i + "]lupamaaraysteksti"} value={lupamaarays.lupamaaraysteksti} labelText="Lupamääräyksen teksti"></SlateTextEditor>
            <CustomDropdown name={"lupamaarays[" + i + "]lupamaarayksenlaji"} defaultValue={lupamaarays.lupamaarayksenlaji} codegroup={rytjCodes.rytj_Lupamaarayshierarkinen} labelText="Lupamääräyksen laji" />
            {/* <TextInput name={"lupamaarays["+i+"]lupamaarayksenlaji"} defaultValue={lupamaarays.lupamaarayksenlaji} labelText="Lupamääräyksen laji"></TextInput> */}
          
          </Block>
        </div>
      )}
      <br />
      <Button icon="plus" variant='secondary' onClick={() => { setLupamaaraykset([...lupamaaraykset, { id: guid(), lupamaaraysteksti: "", lupamaarayksenlaji: "" }]) }}>
        {/* <Icon color='' icon="plus"></Icon> */}
        Lisää uusi lupamääräys
      </Button>
    </>
  )
}

export default PermissionDecisionEdit