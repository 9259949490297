import { Box } from '@mui/material';
import React, { useState } from 'react'
import { Block, Button, Icon, Label, TextInput, Text } from 'suomifi-ui-components';
import { RAKAlueidenkayttopaatoksenLaatija, RAKAlueidenkayttopaatos } from '../../api/rytj/rytj-api'
import guid from '../../utils/guid';
import DateInput from '../DateInput';
import CustomDropdown from '../common/CustomDropdown';
import { rytjCodes } from '../../api/rytj/rytj-codelist';

type Props = { areaDecision: RAKAlueidenkayttopaatos }

interface RAKAlueidenkayttopaatoksenLaatijaWithId extends RAKAlueidenkayttopaatoksenLaatija
{
  id?: string;
}



const AreaDecisionEdit = (props: Props) => {
  const areaDecision = props?.areaDecision;
  // if (areaDecision.alueidenkayttopaatoksenlaatija === undefined) {
  //   areaDecision.alueidenkayttopaatoksenlaatija = [];
  // } 
  

  const [laatijat, setLaatijat] = useState<RAKAlueidenkayttopaatoksenLaatijaWithId[]>(areaDecision.alueidenkayttopaatoksenlaatija||[])

  return (
    <>
      <Label>Alueidenkäyttöpäätöksen laatijat</Label>
    
      {/* <ArrayEdit name="alueidenkayttopaatoksenlaatija"> */}
      { laatijat.map((laatija, i) => 
        <div key={laatija.id||i} >
          {/* <TextInput name="nimike" defaultValue={laatija.nimike} labelText="Nimike"></TextInput> */}
          <Block variant='span' style={{ border: "1px solid hsl(201,7%,58%)", borderRadius: "2px", position: "relative", marginTop: "10px", padding: "10px"}}>
          <Button variant='secondaryNoBorder' style={{position: "absolute", top: 0, right: 0}} onClick={() => { setLaatijat([...laatijat.slice(0,i), ...laatijat.slice(i+1,laatijat.length)])}}>
            <Icon icon='close' onClick={() => { setLaatijat([...laatijat.slice(0,i), ...laatijat.slice(i+1,laatijat.length)])}}></Icon>
          </Button>
          
          <TextInput name={"alueidenkayttopaatoksenlaatija["+i+"]etunimi"} defaultValue={laatija.etunimi} labelText="Etunimi"></TextInput>
          <TextInput name={"alueidenkayttopaatoksenlaatija["+i+"]sukunimi"} defaultValue={laatija.sukunimi} labelText="Sukunimi"></TextInput>
          </Block>
        </div>
      )}
      
      <Button style={{marginTop: "10px"}} icon="plus" variant='secondary' onClick={() => { setLaatijat([...laatijat, { id: guid(), etunimi: "", sukunimi: ""}]) }}>
        Lisää uusi laatija
      </Button>
      {/* </Box> */}
      <br/>
      {/* <Text variant='bold'>Alueiden käyttöpäätöksen tunnus</Text>
      <Box ml={2}> */}
        <TextInput className='input--custom' name={"alueidenkayttopaatoksentunnus.tunnus"} defaultValue={areaDecision?.alueidenkayttopaatoksentunnus?.tunnus} labelText="Alueidenkäyttöpäätöksen tunnus" required></TextInput>
        <TextInput className='input--custom' name={"alueidenkayttopaatoksentunnus.tunnuksenantaja"} defaultValue={areaDecision?.alueidenkayttopaatoksentunnus?.tunnuksenantaja} labelText="Alueidenkäyttöpäätöksen tunnuksen antaja"></TextInput>
      {/* </Box> */}

      <TextInput className='input--custom' name={"digitaalinenalkupera"} defaultValue={areaDecision?.digitaalinenalkupera} labelText="Digitaalinen alkuperä"></TextInput>
      <DateInput required className='input--custom' name={"julkistamisaika"} defaultValue={areaDecision?.julkistamisaika || new Date().toLocaleDateString()} labelText="Julkistamisaika"></DateInput>

      <DateInput required className='input--custom' name={"voimassaolonalkamisajankohta"} defaultValue={areaDecision?.voimassaolonalkamisajankohta || new Date().toLocaleDateString()} labelText="Voimassaolon alkamisajankohta"></DateInput>
      <DateInput required className='input--custom' name={"voimassaolonpaattymisajankohta"} defaultValue={areaDecision?.voimassaolonpaattymisajankohta || new Date().toLocaleDateString()} labelText="Voimassaolon päättymisajankohta"></DateInput>

      {/* TODO alemmat puuttuu mallista, rytjissä eri kuin paatosedit */}

      <CustomDropdown labelText="Sijoittamis-/toteuttamislupa"
                    name="sijoittamis-toteuttamislupa"
                    defaultValue={areaDecision['sijoittamis-toteuttamislupa']}
                    codegroup={rytjCodes.rytj_LuvanSisalto}></CustomDropdown>

      {/* <TextInput className='input--custom' name={"sijoittamistoteuttamislupa"} defaultValue={""} labelText="Sijoittamis-/toteuttamislupa"></TextInput> */}
      <DateInput required className='input--custom' name={"hyvaksymisaika"} defaultValue={areaDecision?.hyvaksymisaika || new Date().toLocaleDateString()} labelText="Hyväksymisaika"></DateInput>    
      <TextInput className='input--custom' name={"alueidenkayttopaatoksenkohde"} defaultValue={areaDecision?.alueidenkayttopaatoksenkohde} labelText="Alueidenkäyttöpäätöksen kohde"></TextInput>

      {/* <DateInput className='input--custom' labelText="Julkistamisaika" defaultValue={areaDecision.lahtotietoaineisto} name={"julkistamisaika"} /> */}
    </>
  )
}

export default AreaDecisionEdit