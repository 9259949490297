import React from 'react';
//import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

import "@fontsource/source-sans-pro";
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import DecisionList, { decisionListLoader } from './decisions/DecisionList';
import DecisionEdit, { decisionPostAction } from './decisions/DecisionEdit';
import DecisionView, { decisionLoader, decisionNewLoader } from './decisions/DecisionView';
import ReactDOM from 'react-dom';
import LoginView, { loginPostAction } from './components/login/LoginView';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );


// const Root = (props: {}}) => {
//   return (
//     <div>Root
//       <Outlet></Outlet>
//     </div>
//   )
// }


const routes = createRoutesFromElements(<>
  <Route path="/login" handle="Kirjautuminen" element={<LoginView/>} action={loginPostAction}></Route>
  <Route path='/' handle="Etusivu" element={<App />} action={decisionPostAction}>
    
    {/* <Route index path="" element={<DecisionList/>} loader={decisionListLoader}></Route> */}
    <Route path="/decision" handle="Päätös">
      <Route path="" element={<DecisionList/>} loader={decisionListLoader}></Route>
      <Route index path="view" element={<DecisionList/>} loader={decisionListLoader}></Route>
      <Route path="view/:id" handle=":id" element={<DecisionView/>} loader={ ({ params }) => { return decisionLoader(params); } /*decisions as any || decisionLoader as any*/}>
      </Route>
      <Route path="edit/:id" handle=":id" element={<DecisionEdit/>} action={decisionPostAction} loader={ ({ params }) => { return decisionLoader(params); }} />
      <Route path="new" handle="Uusi" element={<DecisionEdit/>} action={decisionPostAction} loader={ ({ params }) => { return decisionNewLoader(params); }} />
    </Route>

    <Route path='*' element={<p>404  - ei löytynyt</p>} />
  </Route></>
);
// (window as any).routes = routes;

const router = createBrowserRouter(routes);

// root.render(
//   <React.StrictMode>
//     <RouterProvider router={router}></RouterProvider>
//   </React.StrictMode>
// );

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
  );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
