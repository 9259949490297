import React, { createContext, Dispatch, PropsWithChildren, ReactElement, SetStateAction, useContext, useEffect, useState } from 'react';
import { Link, Navigate, Outlet, redirect, useLoaderData, useLocation, useNavigate, useNavigation } from "react-router-dom";
import './App.css';
import { Box, createTheme, Grid, Paper } from '@mui/material';
//import { makeStyles } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import { RAKPaatos, RytjAPI } from './api/rytj/rytj-api';

//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import 'dayjs/locale/fi';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import { enLocale } from 'date-fns/locale/en-US';
import fiLocale from 'date-fns/locale/fi';
import svLocale from 'date-fns/locale/sv';
import enLocale from 'date-fns/locale/en-US';



import { BaseEditor } from 'slate';
import { ReactEditor } from 'slate-react';
import { Block, Button, Heading, LanguageMenu, LanguageMenuItem, LoadingSpinner, TextInput } from 'suomifi-ui-components';
import Breadcrumbs from './Breadcrumbs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import UserInfo from './components/user-info/UserInfo';
import { useCookies } from 'react-cookie';


type CustomElement = { type: 'paragraph'; children: CustomText[] }
type CustomText = { text: string }

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor
    Element: CustomElement
    Text: CustomText
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 53, 122)'
    }
  },
  typography: {
    h1: { fontSize: '2em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h2: { fontSize: '1.75em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h3: { fontSize: '1.5em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h4: { fontSize: '1.25em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    fontFamily: '"Source Sans Pro", "Helvetica Neue", Arial, sans-serif',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          padding: '5em 3em 1em 3em'
        }
      },
      defaultProps: {
        elevation: 0,
        square: true,
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          padding: '1em 1em 1em 1em'
        }
      }
    }
  }
});



/*
declare module 'react' {
  interface FunctionComponent<P = {}> {
    (props: PropsWithChildren<P>, context?: any): ReactElement<any, any> | null;
  }
}*/

interface IUserData {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
}
export const UserContext = createContext<IUserData>({ isLoggedIn: false, setIsLoggedIn: () => { }, name: "", setName: () => { } });
export const useUserContext = () => useContext(UserContext);
/*type UserContextProps = {
  children?: React.ReactNode,
};
export const UserProvider = ({ children }: PropsWithChildren<UserContextProps>) => {
  //return props.children

  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  const userName = cookies.user && cookies.user.name ? cookies.user.name : null;

  const [isLoggedIn, setIsLoggedIn] = useState(userName !== null);
  const [name, setName] = useState(userName);


  return (
    <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn, name, setName } as IUserData}>
      {children}
    </UserContext.Provider>
  );
}*/

const App: React.FC<{}> = () => {
  const [, setDecisions] = useState<RAKPaatos[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [locale, setLocale] = useState(fiLocale);
  const [rotate, setRotate] = useState(false);
  const location = useLocation();

  const changeLanguage = (language: "FI" | "SV" | "EN") => {
    switch (language) {
      case "FI":
        setLocale(fiLocale);
        break;
      case "EN":
        setLocale(enLocale);
        break;
      case "SV":
        setLocale(svLocale);
        break;
    }
  }

  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const userName = cookies.user && cookies.user.name ? cookies.user.name : null;

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(userName !== null);
  const [name, setName] = useState<string>(userName || "no name");

  useEffect(() => {
    const name = cookies.user && cookies.user.name ? cookies.user.name : null;
    setIsLoggedIn(name !== null);
    setName(name);
    if (name === null) {
      sessionStorage.removeItem("user");
    }
    else {
      sessionStorage.setItem("user", name);
    }
    
  }, [cookies])

  return (
    // <Router>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <UserContext.Provider value={{ isLoggedIn, setIsLoggedIn, name, setName } as IUserData}>
          {/* <header> */}
          <Block variant='header'>
            <Box sx={{ height: '4px', backgroundColor: 'primary.main' }}></Box>

            {/* <Container maxWidth="lg"> */}
            <Grid container
              alignItems="center" sx={{ minHeight: "60px", width: "100%", boxSizing: "border-box", maxWidth: "1200px", paddingRight: "10px", paddingLeft: "10px", marginLeft: "auto", marginRight: "auto" }}>
              <Grid item xs={7}>
                <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Link to="/" style={{ textDecoration: 'none' }}>
                    <Heading className='app-header-title' onMouseOver={() => { setRotate(true) }} onMouseOut={() => { setRotate(false) }} color={'brandBase'} variant='h1hero'>
                      {/* <img src="/favicon.png" alt="" height={32}/>  */}
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 50.799994 50.799994"
                        version="1.1"
                        id="svg12"
                        xmlns="http://www.w3.org/2000/svg">
                        <g id="layer1">
                          <path
                            id="path403"
                            style={{ fill: "hsl(214,100%,24%)", strokeWidth: 0.264583 }}
                            d="m 8.466667,44.449999 c -2.1166637,0 -2.1166667,2.116667 -2.1166667,2.116667 v 0 H 4.2333335 c -2.1166645,0 -2.1166667,2.116667 -2.1166667,2.116667 h 2e-7 H 16.933333 31.75 v 0 c 0,0 -1e-6,-2.116667 -2.116667,-2.116667 h -2.116667 1e-6 c 0,0 -3e-6,-2.116667 -2.116667,-2.116667 h -8.466667 z"
                          />
                          <path
                            id="path1181-1"
                            style={{ fill: "hsl(214,100%,24%)", strokeWidth: 0.264583, transition: "transform 80ms", transform: rotate ? "translate(-9px,30px) rotate(-30deg)" : "" }}
                            d="m 44.948012,35.755021 2.993419,-2.993418 c 0,0 1.49671,-1.496711 10e-7,-2.99342 -1.49671,-1.496709 -2.99342,1e-6 -2.99342,1e-6 0,0 -1.496709,1.496709 -2.993418,-1e-6 l -1.496709,-1.496709 c 0,0 0.748355,-0.748355 -10e-7,-1.496709 -0.748354,-0.748354 -1.496708,1e-6 -1.496708,1e-6 l -1.49671,-1.496711 c 0,0 0.748354,-0.748354 0,-1.496708 -0.748355,-0.748355 -1.496709,-1e-6 -1.496709,-1e-6 l -1.496709,-1.496708 c 0,0 0.748354,-0.748355 -1e-6,-1.49671 -0.748355,-0.748355 -1.496709,-1e-6 -1.496709,-1e-6 l -1.496709,-1.496709 -1.496709,-1.496709 2.993418,-2.993417 1.496709,-1.496711 c 1.49671,1.496709 2.993419,-10e-7 2.993419,-10e-7 0,0 1.496708,-1.49671 -1e-6,-2.993418 L 29.980918,2.8274149 c 0,0 -1.496709,-1.4967096 -2.993419,2e-7 -1.496709,1.4967089 1e-6,2.9934182 1e-6,2.9934182 l -1.496709,1.4967103 -2.993419,2.9934184 c 0,0 -1.49671,-1.4967099 -2.993418,0 -1.49671,1.496709 0,0 0,0 z m 0,0 -2.993418,2.993418 c 0,0 -1.49671,1.49671 -2.993419,1e-6 -1.49671,-1.496709 0,-2.993419 0,-2.993419 0,0 1.49671,-1.49671 1e-6,-2.993418 l -1.49671,-1.49671 c 0,0 -0.748355,0.748355 -1.496709,1e-6 -0.748355,-0.748356 0,-1.496711 0,-1.496711 l -1.496709,-1.496709 c 0,0 -0.748355,0.748356 -1.49671,0 -0.748355,-0.748354 0,-1.496709 0,-1.496709 l -1.496709,-1.49671 c 0,0 -0.748355,0.748355 -1.496709,2e-6 -0.748355,-0.748355 0,-1.496711 0,-1.496711 l -1.496709,-1.496708 -1.49671,-1.496711 -2.993419,2.993419 -1.49671,1.496709 c 1.49671,1.49671 1e-6,2.993418 1e-6,2.993418 0,0 -1.49671,1.49671 -2.993419,1e-6 l -7.483548,-7.483548 c 0,0 -1.496708,-1.496709 1e-6,-2.993419 1.496708,-1.496709 2.993418,1e-6 2.993418,1e-6 l 1.49671,-1.49671 2.993418,-2.993418 c 0,0 -1.496709,-1.496709 0,-2.993418 1.496709,-1.4967099 0,0 0,0 z"
                          />
                        </g>
                      </svg>

                      &nbsp;Päätöseditori
                    </Heading></Link>
                </div>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "right", alignItems: 'center' }}>
                <LanguageMenu name={locale.code?.split("-")[0].toUpperCase()}>
                  <LanguageMenuItem
                    onSelect={() => { changeLanguage("FI") }}
                    selected={locale.code === "fi"}
                  >
                    Suomeksi (FI)
                  </LanguageMenuItem>
                  <LanguageMenuItem
                    onSelect={() => { changeLanguage("SV") }}
                    selected={locale.code === "sv"}
                  >
                    På Svenska (SV)
                  </LanguageMenuItem>
                  <LanguageMenuItem
                    onSelect={() => { changeLanguage("EN") }}
                    selected={locale.code === "en-US"}
                  >
                    In English (EN)
                  </LanguageMenuItem>
                </LanguageMenu>
                {isLoggedIn ?
                  <UserInfo name={name || ""} />
                  :
                  <Link to={"/login?redirect=" + encodeURI(location.pathname)}><Button icon="login" variant="default">Tunnistaudu</Button></Link>
                }

              </Grid>
            </Grid>
            {/* <Typography variant='h1' color='primary.main'>Päätöseditori</Typography> */}



            {/* </Container> */}
            <hr style={{marginTop: 0}}/>
            <Box sx={{ maxWidth: "1200px", paddingRight: "10px", paddingLeft: "10px", marginLeft: "auto", marginRight: "auto" }}>

              <Breadcrumbs key={"murupolku"} />

            </Box>
            <hr style={{marginBottom: 0}}/>
          </Block>
          {/* </header> */}
          {/* <article> */}
          <Block variant='main' style={{marginTop: "10px"}}>
            <Box sx={{ maxWidth: "1200px", paddingRight: "10px", paddingLeft: "10px", marginLeft: "auto", marginRight: "auto" }}>
              {/* <Paper sx={{ minHeight: '80vh' }}> */}
              {/* <RouterProvider router={router} /> */}

              {location.pathname === "/" &&
                <>
                  {/* Note this will redirect to decision view, is there a better way to do this? */}
                  <Navigate replace to="/decision/view" />
                  <LoadingSpinner
                    status="loading"
                    text="Lataa..."
                    textAlign="right"
                    variant="normal"
                  />
                </>
              }
              <Outlet></Outlet>
              {/* </Paper> */}
            </Box>
            {/* </article>
      <footer></footer> */}
          </Block>
          <Block variant='footer'>
            <Box sx={{ maxWidth: "1200px", paddingRight: "10px", paddingLeft: "10px", marginLeft: "auto", marginRight: "auto", textAlign: "center", padding: "30px" }}>       
              <Link to="/data-protection" onClick={(e) => { e.preventDefault(); alert("not implemented yet. Keksejä käytetään kirjautumiseen, ei muuhun.")}}>Tietosuoja</Link>
              &nbsp;|&nbsp;
              <Link to="/accesibility-statement" onClick={(e) => { e.preventDefault(); alert("not implemented yet.")}}>Saavutettavauusseloste</Link>
              &nbsp;|&nbsp;
              <Link to="/contact" onClick={(e) => { e.preventDefault(); alert("not implemented yet.")}}>Yhteystiedot</Link>
            </Box>
          </Block>
        </UserContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
