const guid = (): string => {
    let randomString = "";
    for(;;) {
      // Tested with chrome, seems to create quite random string, probably not the best, but this is quick solution
      const rand = Math.round(Math.random()*1000000000000000).toString(16);
      randomString = randomString.concat(rand);
      if (randomString.length > 31)
        break;
    }
    // According wikipedia 4 stands for random generated guid, there might be also another byte to fix... not sure about that
    const result = `${randomString.slice(0,8)}-${randomString.slice(8,12)}-4${randomString.slice(12,15)}-${randomString.slice(15,19)}-${randomString.slice(19,31)}`;
    return result;
  }
export default guid;