import React, { ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
//import { makeStyles } from '@mui/styles';

import { withHistory } from 'slate-history'

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

import { BaseEditor, Descendant, Range, Node as SlateNode, Location, Transforms, NodeEntry } from 'slate'
import { ReactEditor, useSlate } from 'slate-react'
import { Editable, Slate, withReact } from 'slate-react';
import { createEditor, Editor } from 'slate';
import LinkModal from './LinkModal';

import escapeHtml from 'escape-html'
import { Text } from 'slate'
import { jsx } from 'slate-hyperscript';
import { Button, Label } from 'suomifi-ui-components';
import axios from 'axios';
import { createPortal } from 'react-dom';


//const initialValue:Descendant[] = [];
const initialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: 'A line of text in a paragraph.' }],
  },
]


const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor) as any;
  return marks ? marks[format] === true : false;
}


export function toggleMark(editor: Editor, format: string) {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}


export const isBlockActive = (editor: any, format: any) => {
  const [match] = Editor.nodes(editor, {
    match: (n: any) => n.type === format
  });
  return !!match;
};

export const slateSerialize = (node: any) => {

  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    const leaf = node as any;
    if (leaf.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (leaf.italic) {
      string = `<em>${string}</em>`;
    }
    
    if (leaf.underline) {
      string = `<u>${string}</u>`;
    }
    return string
  }

  const children = node.children.map((n: any) => slateSerialize(n)).join('')

  switch (node.type) {
    // case 'quote':
    //   return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children
  }
}


export const slateDeserialize = (el: Element | Node, markAttributes = {}): any => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent)
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes = { ...markAttributes } as any;

  // define attributes for text nodes
  switch (el.nodeName) {
    case "BODY":
    case "P":
      break;
    case "STRONG":
      nodeAttributes.bold = true
      break;
    default:
      break;
  }

  const children = Array.from(el.childNodes)
    .map(node => slateDeserialize(node, nodeAttributes))
    .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return '\n'
    // case 'BLOCKQUOTE':
    //   return jsx('element', { type: 'quote' }, children)
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: (el as Element).getAttribute('href') },
        children
      )
    default:
      return children
  }
}


const LIST_TYPES = ["numbered-list", "bulleted-list"];
export const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n: any) => LIST_TYPES.includes(n.type),
    split: true
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};


export const SlateElement = (props: any) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "link":
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const SlateLeaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.highlight) {
    children = <span style={{backgroundColor: "#ddeeff"}}>{children}</span>
  }
  if (leaf.important) {
    children = <span style={{backgroundColor: "hsl(166, 54%, 90%)"}}>{children}</span>
  }

  return <span {...attributes}>{children}</span>;
};


const withInlineLinks = (editor: BaseEditor & ReactEditor): BaseEditor & ReactEditor => {
  const { isInline } = editor;

  editor.isInline = (element: any) => {
    return element.type === 'link' ? true : isInline(element);
  };

  return editor
};

export function isLinkActive(editor: Editor) {
  const [link] = Array.from(Editor.nodes(editor, {
    match: (n: any) => n.type === 'link'
  }));
  return !!link
}

export function insertLink(editor: Editor, url: string, text?: string) {
  if (editor.selection) {
    wrapLink(editor, url, text);
  }
}

export function wrapLink(editor: Editor, url: string, text?: string) {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url: url,
    children: isCollapsed ? [{ text: text || url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link as any);
  } else {
    Transforms.wrapNodes(editor, link as any, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
}

export function unwrapLink(editor: Editor) {
  Transforms.unwrapNodes(editor, { match: (n: any) => n.type === 'link' });
}

export function selectionOrEnd(editor: Editor): Location {
  return editor.selection || [0];
}


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

interface ITextEditorToolbar {
  //linkSelection: (location: Location) => void,
  children: React.ReactNode
}
const TextEditorToolbar = ({ children }: ITextEditorToolbar) => {
  const [formats, setFormats] = React.useState(() => ['bold', 'italic', 'link']);

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[],
  ) => {
    setFormats(newFormats);
  };

  return (
    <StyledToggleButtonGroup value={formats} onChange={handleFormat} size="small">{children}</StyledToggleButtonGroup>
  )
}

interface ITextEditorToolbarButtonProps {
  format: "bold" | "italic" | "link",
  block?: true,
  linkSelection?: (location: Location) => void,
  children: React.ReactNode
}
const TextEditorToolbarButton = ({ format, block, linkSelection, children }: ITextEditorToolbarButtonProps) => {
  const editor = useSlate();

  const handleOnMouseDown = (event: SyntheticEvent) => {
    event.preventDefault();

    if (linkSelection) {
      linkSelection(selectionOrEnd(editor));
      return;
    }

    if (block) {
      toggleBlock(editor, format);
    }
    else //if (mark)
    {
      toggleMark(editor, format);
    }

  }

  const isSelected = (editor: any, format: any) => {
    if (block) {
      return isBlockActive(editor, format)
    }
    else {
      return isMarkActive(editor, format)
    }
  }

  return (
    <ToggleButton sx={{ border: 'none', width: "16px", height: "16px", fontSize: "12px" }} value={format} selected={isSelected(editor, format)} onMouseDown={handleOnMouseDown}>
      {children}
    </ToggleButton>
  )
}


export interface Concept {
  uri: string,
  label: string,
}

export function highlightConceptMatches(
  findConcept: (word: string) => Concept | undefined,
  importantConceptPredicate: (uri: string) => boolean, [node, path]: NodeEntry): Range[] {

  const ranges: Range[] = [];

  if (Text.isText(node)) {
    const { text } = node;

    const words = text.split(/[\s.,!?(){}#]/);
    let offset = 0;

    words.forEach((word, i) => {
      const concept = findConcept(word);
      if (concept) {
        ranges.push({
          anchor: { path, offset: offset + word.length },
          focus: { path, offset },
          highlight: !importantConceptPredicate(concept.uri),
          important: importantConceptPredicate(concept.uri)
        } as any)
      }
      offset = offset + word.length + 1;
    })
  }

  return ranges;
}

export function queryFirstNode(context: Node, xPathExpression: string): Node | null {
  return new XPathEvaluator().evaluate(
      xPathExpression, context, null, XPathResult.ANY_UNORDERED_NODE_TYPE, null).singleNodeValue;
}

export function queryFirstText(context: Node, xPathExpression: string): string {
  return queryFirstNode(context, xPathExpression)?.textContent || '';
}

export function useTextConcepts(text: string, terminologyUris: string[], extract: boolean) {
  const [concepts, setConcepts] = useState<Map<string, Concept>>(new Map());
  const [conceptCache, setConceptCache] = useState<Map<string, null | Concept>>(new Map());

  useEffect(() => {
    if (!extract) {
      return;
    }

    const words: string[] = text.split(/[\s.,!?(){}#]/);

    const hasConceptMatch = (word: string): Promise<null | Concept> => {
      //debugger;
      return axios.get('/lakieditori-api/api/concepts', {
        params: {
          query: word.toLowerCase(),
          lemmatize: 'true',
          tag: 'N',
          terminologyUri: terminologyUris.join(",")
        },
        responseType: 'document'
      }).then(res => {
        const resultConcepts = res.data.documentElement;
        
        return resultConcepts.childElementCount > 0 ? {
          uri: queryFirstText(resultConcepts, "/concepts/concept/@uri"),
          label: queryFirstText(resultConcepts, "/concepts/concept/label"),
        } : null;
      });
    };

    const hasCachedConceptMatch = (word: string): Promise<null | Concept> => {
      if (conceptCache.has(word)) {
        return Promise.resolve(conceptCache.get(word) || null);
      } else {
        return hasConceptMatch(word).then(match => {
          setConceptCache(prevCache => new Map(prevCache).set(word, match));
          return match;
        });
      }
    };

    const timer = setTimeout(() => {
      words.forEach(word => {
        hasCachedConceptMatch(word).then(match => {
          if (match) {
            setConcepts(prevConcepts => new Map(prevConcepts).set(word, match));
          }
        });
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [text, terminologyUris, extract, conceptCache]);

  return {concepts};
}


export function selectionOrWord(editor: Editor): null | Range {
  const {selection} = editor;
  return selection && Range.isCollapsed(selection) ? {
    anchor: Editor.before(editor, selection, {unit: "word"}) || selection.anchor,
    focus: Editor.after(editor, selection, {unit: "word"}) || selection.focus
  } : selection;
}

export function isFormatActive(editor: Editor, format: string) {
  const [match] = Array.from(Editor.nodes(editor, {
    match: (n: any) => n[format] === true,
    mode: 'all',
  }));
  return !!match;
}

export function toggleFormat(editor: Editor, format: string, at?: Location) {
  const isActive = isFormatActive(editor, format);
  Transforms.setNodes(
      editor,
      {[format]: isActive ? null : true},
      {at, match: Text.isText, split: true}
  )
}


interface PortalProps {
  children: ReactNode
}
export const Portal = ({children}: PortalProps) => {
  return createPortal(children, document.body)
};

interface ToolbarProps {
  words: Map<string, Concept>,
  linkSelection: (location: Location) => void,
}


const Toolbar: React.FC<ToolbarProps> = ({words, linkSelection}) => {
  const editor = useSlate();
  const selection = selectionOrWord(editor) || [0];

  const doInsertLink = () => {
    if (selection && words.has(Editor.string(editor, selection))) {
      const concept = words.get(Editor.string(editor, selection));
      if (concept) {
        Transforms.select(editor, selection);
        insertLink(editor, concept.uri, Editor.string(editor, selection));
      }
    }
  };

  let text = selection ? words.get(Editor.string(editor, selection))?.label || "" : "";
  text = text.length > 25 ? text.substring(0, 24) + "…" : text;

  //const ed = Editor;

  //ed
  const activeLink = isLinkActive(editor);

  if (!activeLink) {
    if (localStorage.getItem("word") !== text) {
      localStorage.setItem("word", text);
      //linkSelection(selection); // TODO tämän asettaminen tässä aiheuttaa ongelmia!
      setTimeout(() => {
        linkSelection(selection);
      }, 100)
    }
    
    //
  }
  else {
    localStorage.removeItem("word");
  }

  return (
      <div>
        { activeLink ?
          <Button style={{ marginRight: "-2px"}} icon={"close"} onMouseDown={(e) => {
            e.preventDefault();
            unwrapLink(editor);
          }}>
            Poista linkki
          </Button>
            :
          <Button style={{ marginRight: "-2px"}} icon={"plus"} onMouseDown={(e) => {
            e.preventDefault();
            doInsertLink();
          }}>
            <em>{text}</em>
        </Button>
        }

        <Button onMouseDown={(e) => {
          e.preventDefault();
          toggleFormat(editor, "bold", selection);
        }}>
          <FormatBoldIcon sx={{height: "20px", padding: "0px", margin: "-5px"}}></FormatBoldIcon>
        </Button>
        <Button style={{ marginLeft: "-2px"}} onMouseDown={(e) => {
          e.preventDefault();
          toggleFormat(editor, "italic", selection);
        }}>
          <FormatItalicIcon sx={{height: "20px", padding: "0px", margin: "-5px"}}></FormatItalicIcon>
        </Button>
        <Button style={{ marginLeft: "-2px"}} onMouseDown={(e) => {
          e.preventDefault();
          linkSelection(selection);
        }}>
          <InsertLinkIcon sx={{height: "20px", padding: "0px", margin: "-5px"}}></InsertLinkIcon>
        </Button>
      </div>
  );
};


interface TextEditorHoveringToolbarProps {
  words: Map<string, Concept>,
  linkSelection: (location: Location) => void,
}

const TextEditorHoveringToolbar: React.FC<TextEditorHoveringToolbarProps> = ({words, linkSelection}) => {
  const ref = useRef<HTMLDivElement>(null);
  const editor = useSlate();

  useEffect(() => {
    const el = ref.current;
    const selection = selectionOrWord(editor);

    if (!el) {
      return;
    }

    if (!selection || !ReactEditor.isFocused(editor) || !words.has(Editor.string(editor, selection))) {
      el.removeAttribute('style');
      return;
    }

    const domSelection = window.getSelection();

    if (!domSelection || !domSelection.rangeCount) {
      return;
    }

    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();

    if (rect) {
      el.style.opacity = '1';
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
      el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
    }
  });


  return (
      <Portal>
        <div ref={ref} className="portal">
          <Toolbar words={words} linkSelection={linkSelection}/>
        </div>
      </Portal>
  )
};

interface ISlateTextEditorProps {

  name: string,
  value?: string;// Descendant[]
  //setValue: (value: string) => void,
  labelText?: string;
}
const SlateTextEditor = (props: ISlateTextEditorProps) => {
  const editor = useMemo(() => withInlineLinks(withReact(withHistory(createEditor()))), []);

  let parsedValue = [{ type: 'paragraph', children: [{ text: props.value || "" }] }] as SlateNode[]; // Descendant[];;

  if (props.value) {
    if (props.value.startsWith("<")) { // probably html, so try to convert it to slate nodes

      try {
        const document = new DOMParser().parseFromString(props.value.replace(/[\n\r]*/g, ""), 'text/html');
        parsedValue = slateDeserialize(document.body);
      }
      catch {

      }
    }
  }

  const [slateValue, setSlateValue] = useState(parsedValue);

  const [isLinkModalOpen, setLinkModalOpen] = useState(false);
  const [linkModalSelection, setLinkModalSelection] = useState<Location>([0]);

  const renderElement = useCallback((props: any) => <SlateElement {...props} />, []);
  const renderLeaf = useCallback((props: any) => <SlateLeaf {...props} />, []);

  // Sets real initial editor value from properties after it is available
  // useEffect(() => {
  //   if (value) {
  //     const initialValue = deserialize(parseXml(`<root>${value}</root>`).documentElement);
  //     if (initialValue && initialValue.length > 0) {
  //       setEditorValue([{children: initialValue}]);
  //     }
  //   } else {
  //     setEditorValue([{children: [{text: ''}]}]);
  //   }
  // }, [value]);

  // Removes 'onChange' when editor is unmounted to avoid errors when this component is unmounted.
  // useEffect(() => {
  //   return () => {
  //     editor.onChange = () => null
  //   };
  // }, [editor]);

  const editorValue = parsedValue;
  const terminologyUris: string[] = [];
  const [focused, setFocused] = useState<boolean>(false);
  const { concepts } = useTextConcepts(slateValue.map(n => SlateNode.string(n)).join('\n'), terminologyUris, focused);
  const [existingConceptUrls, setExistingConceptUrls] = useState<string[]>([]);

  const decorate = useCallback(([node, path]: NodeEntry) => {
    return focused ? highlightConceptMatches(
      (w) => concepts.get(w),
      (uri) => existingConceptUrls.includes(uri),
      [node, path]) : [];
  }, [focused, concepts]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {props.labelText &&
        <div style={{ marginTop: "16px", marginBottom: "10px" }}>
          <Label>{props.labelText}</Label>
        </div>
      }
      <Box p={1} sx={{ border: '1px solid hsl(201,7%,58%)', borderRadius: '2px', color: 'hsl(0,0%,16%)' }}>
        <input name={props.name} type="hidden" value={JSON.stringify(slateValue)} />
        <Slate editor={editor} value={slateValue as Descendant[]} onChange={(value: any) => {
          setSlateValue(value);
          //setValue(JSON.stringify(value));
        }}>

            {focused &&
          <TextEditorHoveringToolbar
              words={concepts}
              linkSelection={(location: any) => {
                setLinkModalSelection(location);
                setLinkModalOpen(true);
              }}/>}

          <TextEditorToolbar>
            <TextEditorToolbarButton format="bold"><FormatBoldIcon></FormatBoldIcon></TextEditorToolbarButton>
            <TextEditorToolbarButton format="italic"><FormatItalicIcon></FormatItalicIcon></TextEditorToolbarButton>
            <TextEditorToolbarButton block format="link" linkSelection={(location) => {
              setLinkModalSelection(location);
              setLinkModalOpen(true);
            }}><InsertLinkIcon></InsertLinkIcon>
            </TextEditorToolbarButton>
          </TextEditorToolbar>

          <Editable renderElement={renderElement} renderLeaf={renderLeaf} 
            decorate={decorate}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              Transforms.select(editor, [0]);
              //setValue(serialize({children: editorValue}));
              setFocused(false);
            }}
          />

          <LinkModal
            isOpen={isLinkModalOpen}
            close={() => setLinkModalOpen(false)}
            selection={linkModalSelection} />
        </Slate>
      </Box>
    </>
  )
};

export default SlateTextEditor;