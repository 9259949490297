import DOMPurify from 'dompurify';
import React from 'react'
import { Label, Text } from 'suomifi-ui-components'

type Props = {
    label?: string;
    value?: string|number|Date,
    html?: true
}

const LabelValue = (props: Props) => {
  return (<>
    <Label className="label--custom">{props.label}</Label>
    { 
    props.html && typeof props.value === "string" ?
    <Text variant='body' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.value||"")}}></Text>
    :
    <Text variant="body">{props.value || "-"}</Text>
    }
  </>
    
  )
}

export default LabelValue