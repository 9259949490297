import React from 'react'
import { Block, Button, Heading, Icon, Label } from 'suomifi-ui-components'
import { RAKLupapaatos } from '../../api/rytj/rytj-api'
import { getRytjCodeValue, rytjCodes } from '../../api/rytj/rytj-codelist'
import LabelValue from '../LabelValue'

type Props = {
    permissionDecision: RAKLupapaatos
}

function PermissionDecisionView({permissionDecision}: Props) {
  return (
    <div>
      <Heading variant='h3'>Lupapäätös</Heading>

      <LabelValue value={permissionDecision?.lupatunnus?.tunnus} label="Lupatunnus"></LabelValue>
      <LabelValue value={permissionDecision?.lupatunnus?.tunnuksenantaja} label="Lupatunnuksen antaja"></LabelValue>

      <LabelValue value={permissionDecision['sijoittamis-toteuttamislupa']} label="Sijoittamis-/toteuttamislupa"></LabelValue>
      <LabelValue value={permissionDecision?.rakennustyotaloitettavaviimeistaan} label="Rakennustyöt aloitettava viimeistään"></LabelValue>
      <LabelValue value={permissionDecision?.rakennustyotvalmistuttavaviimeistaan} label="Rakennustyöt valmistuttava viimeistään"></LabelValue>

      {/* TODO tee yleisesti käytettävä komponentti -- jos jää aikaa */}
      <Label className='input--custom'>Lupamääräykset</Label>
      {/* <Box ml={2}> */}
      {/* <ArrayEdit name="alueidenkayttopaatoksenlaatija"> */}
      {permissionDecision && permissionDecision.lupamaarays && permissionDecision.lupamaarays.map((lupamaarays, i) =>
        <div key={i} >
          <Block variant='span' className='block--custom-with-borders'>
            <LabelValue value={lupamaarays.lupamaaraysteksti} label="Lupamääräyksen teksti" html></LabelValue>
            <LabelValue value={getRytjCodeValue(rytjCodes.rytj_Lupamaarayshierarkinen as any, lupamaarays.lupamaarayksenlaji, "fi")} label="Lupamääräyksen laji" />          
          </Block>
        </div>
      )}
    </div>
  )
}

export default PermissionDecisionView