import { Box } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import startOfDay from 'date-fns/startOfDay'
import React, { useState } from 'react'
import { Icon, Label } from 'suomifi-ui-components'

type Props = {
  labelText: string,
  name: string,
  //value: string,
  defaultValue?: string,
  disabled?: boolean,
  className?: string,
  required?: true
}

const DateInput = (props: Props) => {

  const [value, setValue] = useState(props.defaultValue||null);

  return (
    <>
    <input type="hidden" name={props.name} value={value||""} />
    <div style={{ marginTop: "16px", marginBottom: "10px" }}>
      <Label className="fi-">{props.labelText}</Label>
    </div>
    <MobileDatePicker
      className={props.className}
      disabled={props.disabled}
      label={props.labelText}
      value={value}
      
      
      onChange={(newValue: any) => {
        if (newValue instanceof Date) {
          setValue(startOfDay(newValue).toISOString());
        }
        else {
          console.log("this should not happen?");
          setValue(newValue.toString());
        }        
      }}
      renderInput={({ inputRef, inputProps, InputProps }) => { return (

        <Box sx={{ display: 'flex', alignItems: 'center', alignContent: "center" }}>
          {/* <TextInput icon="calendar" ref={inputRef} name="paatoksenlainvoimaisuuspaivamaara" labelText="Päätöksen lainvoimaisuuspäivämäärä" value={paatoksenlainvoimaisuuspaivamaara}
                      onClick={ ()=>{
                        (InputProps as any).endAdornment.props.children.props.onClick();
                      }}
                      ></TextInput> */}
          <input className="fi-datepicker-input" disabled={props.disabled} value={value||""} ref={inputRef} {...inputProps} required={props.required} />
          {/* {InputProps?.endAdornment} */}
          <span style={{ pointerEvents: "none", marginLeft: "-30px", marginTop: "2px", opacity: props.disabled ? 0.5 : 1 }}><Icon icon="calendar"></Icon></span>
        </Box>
      )}
    }
    />
    </>
    
  )
}

export default DateInput