import React from 'react'
import { matchPath, RouteObject, useLocation, useMatches, useNavigation, useParams, useRoutes } from 'react-router-dom';
import { Breadcrumb, BreadcrumbLink } from 'suomifi-ui-components';


type Props = {
  //path: ISitePath[];
}

interface ICrumbInfo {
  name: string,
  pathname: string
}

const Breadcrumbs = (props: Props) => {
  const params = useParams();
  const matches = useMatches();

  // TODO fix this solution
  const crumbInfo: ICrumbInfo[] = [];
  for(const match of matches) {
    if (match.handle as string|undefined) {
      let name: string = match.handle as string;
      if (name.startsWith(":")) {
        name = params[name.substring(1)] as string;
      } 
      crumbInfo.push({ name: name, pathname: match.pathname});
    }
  }

  return (<Breadcrumb aria-label='Murupolku'>
    { crumbInfo.map((x, i) => 
      <BreadcrumbLink key={i} href={x.pathname} current={i===crumbInfo.length-1}>
        { x.name }
      </BreadcrumbLink>
    )}
  </Breadcrumb>
  )
  //   return (<Breadcrumb aria-label='Murupolku'>
  //     { props.path.map((x, i) => 
  //         <BreadcrumbLink key={i} href={x.path} current={i==props.path.length-1} >
  //             { x.name }
  //         </BreadcrumbLink>)}
  //     </Breadcrumb>
  //   )
}

export default Breadcrumbs;
