import axios from 'axios'
import React from 'react'
import { useCookies } from 'react-cookie'
import { redirect } from 'react-router-dom'
import { Link, Text } from 'suomifi-ui-components'

type Props = { name: string|null }

const logoutFn = (event: any, removeCookie: any) => { 
  event.preventDefault(); 
  const result = axios.get("/logout").then(x => {
    sessionStorage.removeItem("username");
    removeCookie("user");
    redirect("/");    
  }); 
  
};

const UserInfo = (props: Props) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  return (
    // <div>{cookies.user.name}</div>
    <div style={{display: "inline-block", padding: "0 0 0 10px"}}>
      <Text variant="bold">{props.name||"Ei kirjatunut"}</Text><br/>
      <Link href='/logout' onClick={ (e) => logoutFn(e, removeCookie) }>Kirjaudu ulos</Link>
    </div>
  )
}

export default UserInfo