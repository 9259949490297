import React from 'react'
import { Heading, Label, Text } from 'suomifi-ui-components';
import { RAKAlueidenkayttopaatos } from '../../api/rytj/rytj-api'
import LabelValue from '../LabelValue';

type Props = {
  areaDecision?: RAKAlueidenkayttopaatos;
}

const AreaDecisionView = (props: Props) => {
  const areaDecision = props.areaDecision;
  return (
    <><Heading variant='h3'>Alueidenkäyttöpäätöksen tiedot</Heading>
    
        <Text variant='bold'>Alueidenkäyttöpäätöksen tunnus</Text>
        <LabelValue label="tunnus" value={areaDecision?.alueidenkayttopaatoksentunnus?.tunnus} />
        <LabelValue label="antaja" value={areaDecision?.alueidenkayttopaatoksentunnus?.tunnuksenantaja} />

        <LabelValue label="Digitaalinen alkuperä" value={areaDecision?.digitaalinenalkupera} />
        <LabelValue label="Julkistamisaika" value={areaDecision?.julkistamisaika} />

        <LabelValue label="Voimassaolon alkamisajankohta" value={areaDecision?.voimassaolonalkamisajankohta} />
        <LabelValue label="Voimassaolon päättymisajankohta" value={areaDecision?.voimassaolonpaattymisajankohta} />

        <LabelValue label="Voimassaolon sijoittamis-/toteuttamislupa" value={areaDecision? areaDecision["sijoittamis-toteuttamislupa"] : "" } />
        <LabelValue label="Hyväksymisaika" value={"" /*areaDecision?.hyvaksymisaika*/} />

        <LabelValue label="Alueidenkäyttöpäätöksen kohde" value={areaDecision?.alueidenkayttopaatoksenkohde}></LabelValue>


        {/* <Text variant='bold'>Lähtötietoaineisto</Text>
        { areaDecision?.lahtotietoaineisto && <>
        <LabelValue label="Aineistotunnus" value={areaDecision?.lahtotietoaineisto?.aineistotunnus} />

        </>
        } */}
        
    </>
  )
}

export default AreaDecisionView