import React from 'react'
import { Heading, Label, Text } from 'suomifi-ui-components';
import { RAKPaatos } from '../api/rytj/rytj-api';
import DOMPurify from "dompurify";
import { getRytjCodeValue, rytjCodes } from '../api/rytj/rytj-codelist';
import { useLocalizationContext } from '@mui/x-date-pickers/internals/hooks/useUtils';

type Props = {
    decision: RAKPaatos
}

function DecisionBasicInfo({ decision }: Props) {

    const localization = useLocalizationContext();
    const localeCode = localization.utils.locale?.code;

    return (<><Heading variant='h3'>Päätöksen perustiedot</Heading>

        <Label className="label--custom">Päätöksen nimi</Label>
        <Text variant="body">{decision.paatoksennimi || ""}</Text>

        <Label className="label--custom">Päätösteksti</Label>
       
        <Text variant="body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(decision.paatosteksti || "")}}></Text>

        <Label className="label--custom">Päätöspykälä</Label>
        <Text variant="body">{decision.paatospykala || ""}</Text>

        {/* <Grid container>
                  <Grid item xs={12} sm={6}> */}
        <Label className="label--custom">Päätöksen tekijä</Label>
        <Text variant="body">{decision.paatoksentekija2 || ""}</Text>
        {/* </Grid>
                  <Grid item xs={12} md={6} > */}
        <Label className="label--custom">Päättäjän laji</Label>
        <Text variant="body">{getRytjCodeValue(rytjCodes.rytj_PaatoksenTekija, decision.paattajanLaji, localeCode)}</Text>
        {/* </Grid>

                  <Grid item xs={12}> */}
        <Label className="label--custom">Päätöksen päivämäärä</Label>
        <Text variant="body">{decision.paatoksenantopaivamaara}</Text>
        {/* </Grid>
                  <Grid item xs={12} md={6}> */}
        <Label className="label--custom">Päätöksen julkaisemispäivämäärä</Label>
        <Text variant="body">{decision.julkaisemisenpaivamaara}</Text>
        {/* </Grid>
                  <Grid item xs={12} md={6}> */}
        <Label className="label--custom">Päätöksen lainvoimaisuuspäivämäärä</Label>
        <Text variant='body'>{decision.paatoksenlainvoimaisuuspaivamaara}</Text>
        {/* </Grid>

                  <Grid item sm={12} md={6}> */}
        <Label className="label--custom">Päätöksen tila</Label>
        <Text variant='body'>{decision.paatoksentila || "-"}</Text>
        {/* </Grid>
                  <Grid item sm={12} md={6}> */}
        <Label className="label--custom">Päätöksen tulos</Label>
        <Text variant="body">{getRytjCodeValue(rytjCodes.rytj_PaatoksenTulos, decision.paatoksentulos, localeCode, "-")}</Text>
        {/* </Grid>
                </Grid> */}
    </>
    )
}

export default DecisionBasicInfo