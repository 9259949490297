import {useEffect, useState} from "react";
import axios from "axios";
import { parseXml } from "../../utils/xmlUtils";

export interface Concept {
  uri: string,
  container: string,
  prefLabel: {
    fi: string
  },
  description?: {
    fi: string
  }
}

// export function useConcepts(query: string) {
//   const [concepts, setConcepts] = useState<Concept[]>([]);

//   useEffect(() => {
//     if (query && query.length > 2) {
//       axios.get("http://uri.suomi.fi/terminology/terminology-api/api/v1/integration/resources", {
//         params: {searchTerm: query.toLowerCase()},
//       }).then(res => {
//         if (res?.data?.results) {

//           setConcepts(res.data.results);
//         }
//       });
//     }
//   }, [query]);

//   return {concepts};
// }
export function useConcepts(query: string, terminologyUris: string[]) {
  const [concepts, setConcepts] = useState<Document>(parseXml("<concepts/>"));

  useEffect(() => {
    if (query && query.length > 2) {
      axios.get('/lakieditori-api/api/concepts', {
        params: {
          query: query.toLowerCase(),
          terminologyUri: "" // terminologyUris.join(",")
        },
        responseType: 'document'
      }).then(res => {
        
        const concepts = res.data.children[0]; //getElementsByTagName("concepts");
        for (let i = concepts.children.length - 1; i>=0; i--) {
          
          // Hack, api returns old stuff, try to detect those if it contains (vanha)
          if (concepts.children[i].getElementsByTagName("terminology")[0].innerHTML.indexOf("(vanha)") >= 0)
          {
            concepts.removeChild(concepts.children[i]);
          }
        }
        setConcepts(res.data);
      });
    }
  }, [query]);

  return {concepts};
}