import React from 'react'
import { Dropdown, DropdownItem } from 'suomifi-ui-components'
//import { getRytjCodeValue, getRytjCodes } from '../../api/rytj/rytj-codelist';

type Props = {
  defaultValue?: string;
  labelText: string;
  name: string;
  codegroup: {[code: string]: {[lang: string]: string}}|{[code: string]: string};
}

const CustomDropdown = ({ defaultValue, name, labelText, codegroup }: Props) => {
  const codes = { " ": {"fi": "- valitse -", "en": "- select -", "sv": "- välja -"}, ...codegroup};
  return (
    <Dropdown className="input--custom"
      labelText={labelText}
      name={name}
      defaultValue={defaultValue || " "}
    >
      {Object.keys(codes).map((code, i) =>
        <DropdownItem key={code} value={code}>
          {code === " " ? "- valitse -" : (typeof codes[code as " "] === "string" ? codes[code as " "] : codes[code as " "]["fi"])}
        </DropdownItem>
      )}
    </Dropdown>
  )
}

export default CustomDropdown;
