import React, { useEffect, useState } from "react";
import { Editor, Location, Transforms } from "slate"
import { useSlate } from "slate-react"
import { Button, Modal, ModalContent, ModalFooter, ModalTitle, SearchInput, suomifiDesignTokens as tokens, Text, TextInput } from "suomifi-ui-components";
import { Concept, useConcepts } from "./useConcepts";
import { insertLink, unwrapLink } from './SlateTextEditor';
import axios from 'axios';
import { Drawer } from "@mui/material";
import {useLemma} from "./useLemma";
import { queryElements, queryFirstText } from "../../utils/xmlUtils";


declare module Koodisto {

  export interface Meta {
    pageSize: number;
    from: number;
    resultCount: number;
    totalResults: number;
  }

  export interface PrefLabel {
    fi: string;
    en: string;
    sv: string;
  }

  export interface Description {
    fi: string;
    en: string;
    sv: string;
  }

  export interface Result {
    uri: string;
    type: string;
    container: string;
    prefLabel: PrefLabel;
    description: Description;
    status: string;
    created: Date;
    modified: Date;
  }

  export interface ISearchResult {
    meta: Meta;
    results: Result[];
  }

}


enum LinkView {
  CONCEPT_LIST,
  WEB,
}

interface Props {
  isOpen: boolean,
  close: () => void,
  selection: Location,
}



const LinkModal = ({ isOpen, close, selection }: Props) => {
  const editor = useSlate();

  const [linkText, setLinkText] = useState<string>('');
  const [linkUrl, setLinkUrl] = useState<string>('');
  const [linkView, setLinkView] = useState<LinkView>(LinkView.CONCEPT_LIST);

  /*const [lemma, setLemma] = useState("");

  useEffect(() => {
    //debugger;
    const word = Editor.string(editor, selection);
    axios.get('/lakieditori-api/api/lemma', {
      params: {word},
      responseType: 'text'
    }).then(res => {
      setLemma(res.data ? JSON.parse(res.data) : word);
    });
  }, [isOpen, editor, selection]);*/

  /*useEffect(() => {
    //if (isOpen) {
      setLinkText(lemma);

      const [link] = Editor.nodes(editor, { match: n => { return (n as any).type === 'link' }, at: selection });
      setLinkUrl((link && link.length) > 0 ? ((link[0] as any).url + "") : '');
    //}
  }, [lemma]);*/


  useEffect(() => {
    // when modal is opened, read selected text and possible existing link URL
    if (isOpen) {
      //debugger;
      const text = Editor.string(editor, selection) || localStorage.getItem("word") || "";

      setLinkText(text);
      const [link] = Editor.nodes(editor, { match: n => { return (n as any).type === 'link' }, at: selection });
      setLinkUrl((link && link.length) > 0 ? ((link[0] as any).url + "") : '');
    }
  }, [isOpen, editor, selection]);

  function insertLinkAndClose() {
    Transforms.select(editor, selection);

    if (linkUrl) {
      if (Editor.string(editor, selection) !== linkText) {
        Transforms.delete(editor);
      }
      insertLink(editor, linkUrl, linkText);
    } else {
      unwrapLink(editor);
    }

    //Transforms.select(editor, selection);

    closeModal();

    closeModal();
  }

  function closeModal() {
    setLinkText('');
    setLinkUrl('');
    setLinkView(LinkView.CONCEPT_LIST);
    close();
  }

  return (
    <Drawer open={isOpen} variant="persistent" anchor="right" style={{ width: "100%", display: "flex", flexDirection: "column", maxHeight: "100vh" }}>
      {/* <Modal visible={isOpen} appElementId="root" style={{ width: "420px", height: "90%", minWidth: "375", minHeight: "480px", marginRight: "40px", marginLeft: "auto" }}>
      <ModalContent style={{ position: "relative" }}> */}

      <div className="drawer-header">
        <h3>Lisää linkki</h3>
        <div style={{ float: "right", marginTop: "-6px" }}>
          
          <Button style={{ float: "right" }} variant={linkView === LinkView.CONCEPT_LIST ? 'link' : 'secondaryNoBorder'}
            onClick={() => setLinkView(LinkView.CONCEPT_LIST)}>
            Käsitelinkki
          </Button>

          <Button style={{ float: "right" }} variant={linkView === LinkView.WEB ? 'link' : 'secondaryNoBorder'}
            onClick={() => setLinkView(LinkView.WEB)}>
            Web-linkki
          </Button>
        </div>
      </div>
      <hr />

      {/* <div className='drawer-content'> */}

        {linkView === LinkView.CONCEPT_LIST &&
          <ConceptLink className="drawer-content"
            linkUrl={linkUrl} setLinkUrl={setLinkUrl}
            linkText={linkText} setLinkText={setLinkText}  /> }
        {linkView === LinkView.WEB &&
          <div className="drawer-content">
            <WebLink
              linkUrl={linkUrl} setLinkUrl={setLinkUrl}
              linkText={linkText} setLinkText={setLinkText} />

          {/* <TextInput className='input--custom' labelText='Linkin osoite'
            name='url'
            value={linkUrl}
            onChange={(e) => setLinkUrl((e || "").toString())} /> */}

          <TextInput className='input--custom' labelText='Linkin teksti'
            name='url'
            value={linkUrl}
            onChange={(e) => setLinkText((e || "").toString())} />
          </div>
        }

      {/* </div> */}
      <div className="drawer-footer">
        <Button icon={"plus"} onClick={insertLinkAndClose}>
          Lisää
        </Button>
        <Button variant='secondary' icon={"close"} onClick={closeModal}>
          Peruuta
        </Button>
      </div>
   
    </Drawer>
    // <Modal visible={isOpen} appElementId="root" style={{ width: "420px", height: "90%", minWidth: "375", minHeight: "480px", marginRight: "40px", marginLeft: "auto" }}>
    //   <ModalContent style={{ position: "relative" }}>
    //     <div style={{ float: "right", marginTop: "-6px" }}>

    //       <Button style={{ float: "right" }} variant={linkView === LinkView.CONCEPT_LIST ? 'link' : 'secondaryNoBorder'}
    //         onClick={() => setLinkView(LinkView.CONCEPT_LIST)}>
    //         Käsitelinkki
    //       </Button>

    //       <Button style={{ float: "right" }} variant={linkView === LinkView.WEB ? 'link' : 'secondaryNoBorder'}
    //         onClick={() => setLinkView(LinkView.WEB)}>
    //         Web-linkki
    //       </Button>
    //     </div>
    //     <ModalTitle>
    //       Lisää linkki
    //     </ModalTitle>
    //     <hr />

    //     <div className='link-modal-search-results'>

    //       {linkView === LinkView.CONCEPT_LIST &&
    //         <ConceptLink
    //           linkUrl={linkUrl} setLinkUrl={setLinkUrl}
    //           linkText={linkText} setLinkText={setLinkText} />}
    //       {linkView === LinkView.WEB &&
    //         <>
    //           <WebLink
    //             linkUrl={linkUrl} setLinkUrl={setLinkUrl}
    //             linkText={linkText} setLinkText={setLinkText} />

    //         <TextInput className='input--custom' labelText='Linkin osoite'
    //           name='url'
    //           value={linkUrl}
    //           onChange={(e) => setLinkUrl((e || "").toString())} />

    //         <TextInput className='input--custom' labelText='Linkin teksti'
    //           name='url'
    //           value={linkUrl}
    //           onChange={(e) => setLinkText((e || "").toString())} />
    //         </>
    //       }

    //     </div>
    //   </ModalContent>
    //   <ModalFooter>
    //     <Button icon={"plus"} onClick={insertLinkAndClose}>
    //       Lisää
    //     </Button>
    //     <Button variant='secondary' icon={"close"} onClick={closeModal}>
    //       Peruuta
    //     </Button>
    //   </ModalFooter>
    // </Modal>
  )
};

interface LinkViewProps {
  linkUrl: string,
  setLinkUrl: (url: string) => void,
  linkText: string,
  setLinkText: (text: string) => void,
  className?: string
}

const WebLink: React.FC<LinkViewProps> = ({ linkUrl, setLinkUrl, className }) => {
  return (
    <div className={className}>
      <TextInput labelText='Linkin osoite (URL)'
        name='url'
        value={linkUrl}
        onChange={(e) => setLinkUrl((e || "").toString())} />
    </div>
  );
};

const ConceptLink: React.FC<LinkViewProps> = ({ linkUrl, setLinkUrl, linkText, setLinkText, className }) => {
  const [query, setQuery] = useState("");
  //const [concepts, setConcepts] = useState<Concept[]>([]);
  const {lemma} = useLemma(linkText);
  const {concepts} = useConcepts(query, []);

  // useEffect(() => {
  //   // action on update of movies
  //   setQuery(linkText);
  // }, [linkText]);

  useEffect(() => {
    setQuery(lemma);
  }, [lemma]);
  

  return (
    <div className={className} style={{width: "320px", maxWidth: "320px"}}>
      <SearchInput
        clearButtonLabel="Tyhjennä haku"
        labelText="Etsi käsitettä"
        searchButtonLabel="Hae"
        value={query} onChange={(e) => setQuery((e || "").toString())} />

      <Text variant='bold'>Hakutulokset</Text>
      <div style={{ flexGrow: 1, overflowY: "auto" }}>
        {/* {concepts.map((c, i) => {
          return <div key={i} onClick={() => {
            setLinkUrl(c.uri);
            setLinkText(!linkText ? c.prefLabel.fi : linkText);
          }} style={{
            borderTop: "1px solid #ccc", // todo
            background: c.uri === linkUrl
              ? tokens.colors.highlightLight2 //highlightLight50
              : tokens.colors.whiteBase
          }}>
            <ConceptRow concept={c} expanded={c.uri === linkUrl} />

          </div>;
        })} */}
        <ConceptTable
            concepts={concepts}
            linkUrl={linkUrl} setLinkUrl={setLinkUrl}
            linkText={linkText} setLinkText={setLinkText}/>
      </div>
    </div>
  );
};

interface ConceptTableProps extends LinkViewProps {
  concepts: Document,
}

const ConceptTable: React.FC<ConceptTableProps> = ({concepts, linkUrl, setLinkUrl, linkText, setLinkText}) => {
  return (
      <table style={{marginTop: tokens.spacing.s}}>
        <thead>
        <tr>
          <th>Hakutulokset ({concepts.documentElement.childNodes.length})</th>
        </tr>
        </thead>
        <tbody>
        {queryElements(concepts.documentElement, "/concepts/concept").map((e, i) => {
          const uri = queryFirstText(e, "@uri");
          const label = queryFirstText(e, "label");

          return <tr key={i} onClick={() => {
            setLinkUrl(uri);
            setLinkText(!linkText ? label : linkText);
          }} style={{
            background: uri === linkUrl
                ? tokens.colors.highlightLight2
                : tokens.colors.whiteBase
          }}>
            <td>
              <ConceptRow concept={e} expanded={uri === linkUrl}/>
            </td>
          </tr>;
        })}
        </tbody>
      </table>
  );
};

interface ConceptRowProps {
  concept: Element
  expanded: boolean,
}

const ConceptRow: React.FC<ConceptRowProps> = ({concept, expanded}) => {
  const uri = queryFirstText(concept, "@uri");
  const label = queryFirstText(concept, "label");
  const definition = queryFirstText(concept, "definition");
  const terminologyLabel = queryFirstText(concept, "terminology/label");

  return (
      <div>
        <span style={{color: tokens.colors.depthDark2}}>
          {terminologyLabel}
        </span>
        <br/>
        <span style={{
          color: tokens.colors.highlightBase,
          fontSize: tokens.values.typography.heading4.fontSize.value,
          fontWeight: tokens.values.typography.heading4.fontWeight
        }}>
          {label}
        </span>
        {expanded &&
        <div>
          <div>{definition}</div>
          <div>
            <a href={uri} target={"_blank"} rel="noreferrer">
              Katso lisätiedot sanastopalvelusta...
            </a>
          </div>
        </div>}
      </div>
  );
};

export default LinkModal;
