import { Card, CardActions, CardContent, Grid } from "@mui/material";
import { useLocalizationContext } from "@mui/x-date-pickers/internals/hooks/useUtils";
import DOMPurify from "dompurify";
import React, { useContext, useState } from "react";
import { Link, Outlet, useLoaderData, useNavigation } from "react-router-dom";
import { Button, Heading, LoadingSpinner, SearchInput, Text } from "suomifi-ui-components";
import { RAKPaatos, RytjAPI } from "../api/rytj/rytj-api";
import { getRytjCodeValue, rytjCodes } from "../api/rytj/rytj-codelist";
import { UserContext } from "../App";

interface Props {
  //decisions: RAKPaatos[],
  //loading: boolean
}
const DecisionList: React.FC<Props> = (props) => {
  const [filter, setFilter] = useState<string>("");

  const decisions = useLoaderData() as RAKPaatos[];
  const navigation = useNavigation();
  const loading = navigation.state === "loading";
  /*if (navigation.state === "loading") {
    return <Card>
      <CardContent>
        <LoadingSpinner
          status="loading"
          text="Lataa..."
          textAlign="right"
          variant="normal"
        />
      </CardContent>
    </Card>
  }*/
  type SearchInputValue = string | number | undefined;
  const onFilterChange = (value: SearchInputValue) => {
    setFilter(value as string);
  };

  const user = useContext(UserContext);
  const isLoggedIn = user?.isLoggedIn;

  const localization = useLocalizationContext();
  const localeCode = localization.utils.locale?.code;

  const filterFn = (paatos: RAKPaatos): boolean => {
    return JSON.stringify(paatos).toLocaleLowerCase().indexOf(filter.toLocaleLowerCase())>=0
  }

  
  return <React.Fragment>
    
    <Grid container mb={2} mt={2} columnSpacing={{xs: 2}}>
      <Grid item xs={8} md={4}>
        <Heading variant='h2'>Päätökset</Heading>
        <br/>
        <SearchInput
          fullWidth
          clearButtonLabel="Tyhjennä suodatus"
          labelText="Hae päätöksiä"
          searchButtonLabel="Hae"
          onChange={onFilterChange}
        />
      </Grid>

      <Grid style={{ flexGrow: "1" }}></Grid>
      <Grid item>
        <Link to="/decision/new">
          <Button disabled={!isLoggedIn}>Luo uusi</Button>
        </Link>
      </Grid>
    </Grid>

    {loading ?
      <Card>
        <CardContent>
          <LoadingSpinner
            status="loading"
            text="Noutaa päätöksiä..."
            textAlign="right"
            variant="normal"
          />
        </CardContent>
      </Card>
      :
      <Grid container mb={2} mt={2} columnSpacing={{xs: 2}} rowSpacing={2}>
      
        {(decisions || []).filter(x => filterFn(x)).map((decision, i) => 
        <Grid item key={i} xs={12} sm={6} md={4}>
          <Card>
            <CardContent style={{ height: "220px" }}>
              <Heading variant='h4' className="ellipsis-text">{decision.paatoksennimi}</Heading>
              {/* <Text variant='lead'>{decision.paatosteksti}</Text> */}
              <div className="fade-text">
              <Text variant="body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(decision.paatosteksti || "")}}></Text>
              </div>
              <Text variant='body'></Text><br/>
              <Text variant='bold'>{decision.paatoksentila}</Text><br/>   
              <Text variant='bold'>{getRytjCodeValue(rytjCodes.rytj_PaatoksenTulos, decision.paatoksentulos, localeCode)}</Text>
            </CardContent>
            <CardActions>
              <Grid container>
                <Grid item xs={6}>
                  <Link to={"/decision/view/" + encodeURI(decision.versiointitiedot?.identiteettitunnus||"")}>
                    <Button variant='link'>Näytä</Button> 
                  </Link>
                </Grid>
                <Grid item xs={6} style={{textAlign:"right"}}>
                {decision.paatoksentekija2}<br/>
                {decision.paatoksenantopaivamaara /*&& !decision.paatoksenantopaivamaara.startsWith("00")*/ && new Date(decision.paatoksenantopaivamaara as string).toLocaleDateString()}
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        )}
      </Grid>
    }

    {/* <Outlet></Outlet>
     */}
  </React.Fragment>
};

export default DecisionList

// [RAKPaatos | undefined, React.Dispatch<React.SetStateAction<RAKPaatos | undefined>>]
export async function decisionListLoader(): Promise<RAKPaatos[]> {
  const api = new RytjAPI();
  const result = await api.paatos.paatosGetAll({ select: 'versiointitiedot', browserCache: 5 });
  return result.data;
}
