import { defaultDecorate } from "slate-react/dist/components/editable";

export const rytjCodes = { 
  rytj_RY_DigitaalinenAlkupera: {
    "01": "Tietomallin mukaan laadittu",
    "02": "Kokonaan digitoitu",
    "03": "Osittain digitoitu",
    "04": "Rajaus digitoitu",
  },
  rytj_julkisuus: {
    "1": "Julkinen asiakirja",
    "2": "Sisältää turvaluokiteltua tietoa",
    "3": "Sisältää salassapidettävää tietoa",
    "4": "Tiedon jakaminen muille kuin viranomaisille vaatii tarkistamista",
  },
  rytj_sailytysaika: {
    "01": "Säilytetään pysyvästi",
    "02": "Säilytetään määräajan",
    "0201": "2 vuotta",
    "020101": "Päätöksen lainvoimaisuuspäivä",
    "020102": "Päätöksen antopäivä",
    "020103": "Rakennuksen valmistuminen",
    "0202": "5 vuotta",
    "020201": "Päätöksen lainvoimaisuuspäivä",
    "020202": "Päätöksen antopäivä",
    "020203": "Rakennuksen valmistuminen",
    "0203": "10 vuotta",
    "020301": "Päätöksen lainvoimaisuuspäivä",
    "020302": "Päätöksen antopäivä",
    "020303": "Rakennuksen valmistuminen",
    "0204": "15 vuotta",
    "020401": "Päätöksen lainvoimaisuuspäivä",
    "020402": "Päätöksen antopäivä",
    "020403": "Rakennuksen valmistuminen",
    "0205": "Rakennuksen purkamiseen asti",
  },
  rytj_henkilotietosisalto: {
    "1": "Ei sisällä henkilötietoja",
    "2": "Suunnittelija, päätöksen tekijä, lausunnonantaja, selvityksen tekijä",
    "3": "Hakija",
    "4": "Erityisiä henkilötietoryhmiä koskevat henkilötiedot (esim. terveystiedot, rikostiedot sekä muut arkaluontoiset tiedot)",
    "5": "Muu henkilötieto",
  },
  rakrek_erityistatoimintaavartenrakennettavanalueentyyppi: {
    "01": "Pysäköintialue",
    "02": "Puisto",
    "03": "Tori",
  },
  rytj_hissi: {
    "01": "Henkilöhissi",
    "02": "Palomieshissi",
    "03": "Pystyhissi",
    "04": "Porrashissi",
    "05": "Tavarahissi",
    "99": "Muu",
  },
  jhs_huonelaji: {
    "01": "asuinhuone",
    "02": "keittiö",
    "04": "pesuhuone",
    "03": "wc",
    "05": "sauna",
    "06": "kulkutila",
    "07": "tekninen tila",
    "08": "varasto",
    "09": "opetustila",
  },
  rytj_Huoneistotyyppi: {
    "01": "Asuinhuoneisto",
    "02": "Toimitila",
  },
  rytj_Keittiotyyppi: {
    "01": "Keittiö",
    "02": "Keittokomero",
    "03": "Keittotila",
  },
  rytj_ilmanvaihto: {
    "01": "Painovoimainen ilmanvaihto",
    "02": "Koneellinen poistoilmanvaihto",
    "03": "Koneellinen tulo- ja poistoilmanvaihto",
  },
  rytj_jaahdytystapa: {
    "01": "Ilmajäähdytteinen",
    "02": "Vesijäähdytteinen",
    "03": "Ei kiinteää jäähdytyslaitetta",
  },
  rakrek_ETRS89: {
    "GK19": "ETRS89 / GK19FIN",
    "GK20": "ETRS89 / GK20FIN",
    "GK21": "ETRS89 / GK21FIN",
    "GK22": "ETRS89 / GK22FIN",
    "GK23": "ETRS89 / GK23FIN",
    "GK24": "ETRS89 / GK24FIN",
    "GK25": "ETRS89 / GK25FIN",
    "GK26": "ETRS89 / GK26FIN",
    "GK27": "ETRS89 / GK27FIN",
    "GK28": "ETRS89 / GK28FIN",
    "GK29": "ETRS89 / GK29FIN",
    "GK30": "ETRS89 / GK30FIN",
    "GK31": "ETRS89 / GK31FIN",
  },
  rytj_julkisivunrakennusaine: {
    "00": "Ei tiedossa",
    "01": "Betoni",
    "02": "Tiili",
    "03": "Metallilevy",
    "04": "Kivi",
    "05": "Puu",
    "06": "Lasi",
    "99": "Muu",
  },
  rytj_RY_Kaavalaji: {
    "1": "Maakuntakaava",
    "11": "Kokonaismaakuntakaava",
    "12": "Vaihemaakuntakaava",
    "2": "Yleiskaava",
    "21": "Yleiskaava",
    "22": "Vaiheyleiskaava",
    "23": "Osayleiskaava",
    "24": "Kuntien yhteinen yleiskaava",
    "3": "Asemakaava",
    "31": "Asemakaava",
    "32": "Vaiheasemakaava",
    "33": "Ranta-asemakaava",
    "25": "Oikeusvaikutukseton yleiskaava",
    "34": "Vaiheranta-asemakaava",
    "35": "Maanalaisten tilojen asemakaava",
    "26": "Maanalainen yleiskaava",
    "39": "Asemakaava (ohjeellinen tonttijako)",
  },
  rytj_RY_KaavanElinkaaritila: {
    "01": "Kaavoitusaloite",
    "02": "Vireilletullut",
    "03": "Valmistelu",
    "04": "Kaavaehdotus",
    "05": "Tarkistettu kaavaehdotus",
    "06": "Hyväksytty kaava",
    "07": "Oikaisukehotuksen alainen",
    "08": "Valituksen alainen",
    "09": "Oikaisukehotuksen alainen ja valituksen alainen",
    "10": "Osittain voimassa",
    "11": "Voimassa",
    "12": "Kumottu",
    "13": "Kumoutunut",
    "14": "Rauennut",
    "15": "Hylätty",
  },
  rytj_RY_MaanalaisuudenLaji: {
    "01": "Maanalainen",
    "02": "Maanpäällinen",
  },
  rytj_RY_Sitovuuslaji: {
    "01": "Sitova",
    "02": "Ohjeellinen",
  },
  rytj_RY_KaavanMuutostieto: {
    "01": "Ensimmäinen asemakaava",
    "02": "Asemakaavan kaavayksikön rajojen muutos",
    "03": "Asemakaavamääräysten muutos",
  },
  rytj_kantavanrakenteenrakennusaine: {
    "00": "Ei tiedossa",
    "01": "Betoni",
    "02": "Tiili",
    "03": "Teräs",
    "04": "Puu",
    "99": "Muu",
  },
  rytj_Katselmuslaji: {
    "01": "Aloituskokous",
    "02": "Pohjakatselmus",
    "03": "Sijaintikatselmus",
    "04": "Rakennekatselmus",
    "05": "Lämpö-, vesi- ja ilmanvaihtolaitteiden katselmus",
    "06": "Osittainen loppukatselmus",
    "07": "Loppukatselmus",
  },
  rytj_KatselmuksenTilanne: {
    "01": "Hyväksytty",
    "02": "Korjattava",
    "03": "Hyväksytty ehdolla",
  },
  rytj_OsittainenLopullinen: {
    "01": "Katselmus suoritettu osittaisena",
    "02": "Katselmus on lopullinen",
  },
  rytj_RY_LahtotietoaineistonLaji: {
    "01": "Aluerajat",
    "02": "Energia",
    "03": "Ihmisten elinolot ja elinympäristö",
    "04": "Ilma ja ilmasto",
    "05": "Kaupunkikuva ja maisema",
    "06": "Kulttuuriympäristö",
    "07": "Liikenne",
    "08": "Luonto ja luonnonvarat",
    "09": "Palveluverkko",
    "10": "Maa- ja kallioperä, maanpeite",
    "11": "Pohjakartta",
    "12": "Rakennukset ja rakenteet",
    "13": "Suunnitelmat",
    "1301": "Alueidenkäytön suunnitelmat",
    "1302": "Muut suunnitelmat",
    "14": "Työpaikat ja elinkeinoelämä",
    "15": "Vesi- ja jätehuolto",
    "16": "Vesien hoito ja suojelu",
    "17": "Viestintäverkko",
    "18": "Virkistys ja viherrakenne",
    "19": "Väestö",
    "20": "Yhdyskuntarakenne",
    "21": "Yhdyskuntatalous",
    "22": "Muu lähtötietoaineisto",
  },
  rytj_lammitysenergianlahde: {
    "01": "Kauko- tai aluelämpö",
    "02": "Kevyt polttoöljy",
    "03": "Raskas polttoöljy",
    "04": "Sähkö",
    "05": "Kaasu",
    "06": "Kivihiili",
    "07": "Puu",
    "08": "Turve",
    "09": "Maalämpö tms.",
    "10": "Aurinkoenergia",
    "11": "Lämpöpumppu",
    "1101": "Maalämpöpumppu",
    "1102": "Ilmalämpöpumppu",
    "1103": "Poistoilmalämpöpumppu",
    "1104": "Ilma-vesilämpöpumppu",
    "99": "Muu",
  },
  rytj_lammitystapa: {
    "01": "Vesikeskuslämmitys",
    "02": "Ilmakeskuslämmitys",
    "03": "Sähkölämmitys",
    "04": "Uuni-takka-kamiinalämmitys",
    "05": "Aurinkolämmitys",
    "06": "Ilmalämpöpumppu",
    "07": "Ei kiinteää lämmityslaitetta",
    "99": "Muu",
  },
  rytj_LuvanSisalto: {
    "01": "Sekä sijoittamis- että toteuttamislupa",
    "02": "Sijoittamislupa",
    "03": "Toteuttamislupa",
  },
  rytj_HakemuksenTyyppi: {
    "01": "Rakentamislupahakemus",
    "02": "Poikkeamislupahakemus",
    "03": "Maisematyölupahakemus",
    "04": "Purkamislupahakemus",
  },
  rytj_Lupamaarayshierarkinen: {
    "M01": "Katselmukset",
    "M0101": "Aloituskokous",
    "M0102": "Pohjakatselmus",
    "M0103": "Sijaintikatselmus",
    "M0104": "Rakennekatselmus",
    "M0105": "Lämpö-, vesi- ja ilmanvaihtolaitteiden katselmus",
    "M0106": "Osittainen loppukatselmus",
    "M0107": "Loppukatselmus",
    "M02": "Autopaikat",
    "M0201": "Kiinteistöllä",
    "M0202": "Kiinteistön ulkopuolella",
    "M03": "Väestönsuojat",
    "M0301": "Kiinteistöllä",
    "M0302": "Kiinteistön ulkopuolella",
    "M04": "Erityissuunnitelmat",
  },
  rytj_RY_MaankayttorajoitustenLaji: {
    "01": "Asemakaavan rakennuskielto",
    "02": "Asemakaavan toimenpiderajoitus",
    "03": "Yleiskaavan rakennuskielto",
    "04": "Yleiskaavan toimenpiderajoitus",
    "05": "Yleiskaavan rakentamisrajoitus",
    "06": "Yleiskaavan suunnittelutarvealue",
    "07": "Maakuntakaavan rakentamisrajoitus",
    "08": "Rakennusjärjestyksen suunnittelutarvealue",
  },
  rytj_RY_MaankayttorajoituksenSyntytapa: {
    "01": "Automaattinen maankäyttörajoitus",
    "02": "Päätöksellä määrätty maankäyttörajoitus",
    "03": "Voimassa olevan maankäyttöpäätöksen rajoitus",
  },
  rytj_ostoenergia: {
    "01": "Uusiutuva polttoaine",
    "02": "Fossiilinen polttoaine",
    "03": "Kaukolämpö",
    "04": "Kaukojäähdytys",
    "05": "Sähkö",
  },
  rytj_paatoksennimi: {
    "01": "Asemakaava ja -määräykset",
    "02": "Ranta-asemakaava ja -määräykset",
    "03": "Erillinen tonttijako",
    "04": "Yleiskaava ja -määräykset",
    "05": "Kuntien yhteinen yleiskaava",
    "06": "Maakuntakaava ja -määräykset",
    "07": "Merialuesuunnitelma ja meren hoitoalue",
    "08": "Yleisen alueen prosessit",
    "09": "Hulevesisuunnitelma",
    "10": "Kehittämisalue-menettely",
    "11": "Rakennuskielto (Asemakaavan laatimiseksi)",
    "12": "Rakennuskielto (Yleiskaava)",
    "13": "Suunnittelutarvealue",
    "14": "Suunnittelutarve ranta-alueilla",
    "15": "Maankäyttösopimus",
    "16": "Rakentamisrajoitus (Maakuntakaava)",
    "17": "Rakentamisrajoitus (Asemakaava)",
    "18": "Rakentamisrajoitus (Yleiskaava)",
    "19": "Veden johtaminen ja ojittaminen",
    "20": "Rakennuslupa",
    "21": "Toimenpidelupa",
    "22": "Rakennuksen purkamislupa",
    "23": "Poikkeamispäätös",
    "24": "Alueellinen poikkeaminen",
    "25": "Rakennuslupa tuulivoimalan rakentamiseen",
    "26": "Suunnittelutarveratkaisu",
    "27": "Kiinteistöjen yhteisjärjestely",
    "28": "Ympäristön hoito",
    "29": "Tilapäinen rakennus",
    "30": "Toimenpiderajoitukset yleiskaavassa",
    "31": "Maisematyölupa",
    "32": "Kadun rakentaminen",
    "33": "Kadunpitopäätös",
    "34": "Katualueen haltuunotto",
    "35": "Lunastuspäätökset",
    "36": "Rakennusjärjestys",
    "37": "Rakennusrasiteet",
    "38": "Yhdyskuntateknisten laitteiden sijoittaminen",
    "39": "Vähäisten laitteiden sijoittaminen",
    "40": "Kiinteistöjen yhteisjärjestelyt",
    "41": "Valtakunnalliset alueidenkäyttötavoitteet",
    "42": "Asemakaava maanalaisia tiloja varten",
    "43": "Kaavamääräykset",
    "44": "Vaikutusten selvittäminen kaavaa laadittaessa",
    "45": "Kansallinen kaupunkipuisto",
    "46": "Vähittäiskaupan suuryksikön sijoittaminen",
    "47": "Alueellinen päätös rakennusluvan erityisistä edellytyksistä suunnittelutarvealueella",
    "48": "Naapurin alueen käyttäminen",
  },
  rytj_PaatoksenTulos: {
    "01": {"fi": "Myönnetty", "en": "Approved"},
    "02": {"fi": "Evätty", "en": "Denied"},
  },
  rytj_PaatoksenTekija: {
    "01": "Viranhaltija",
    "02": "Monijäseninen päätöksentekoelin",
  },
  rytj_PoikkeamisenLaji: {
    "01": "Asuntojen lukumäärä",
    "02": "Autopaikat",
    "03": "Rakennussuojelu",
    "04": "Rakennuskielto",
    "05": "Rakennusalan ylittäminen",
    "06": "Rakennusjärjestys",
    "07": "Rakennuspaikan minimikokovaatimus",
    "08": "Rantarakentaminen",
    "09": "Rakennuksen käyttötarkoitus",
    "10": "Rakennusoikeus",
    "11": "Kerrosluku",
    "12": "Tontin lohkomattomuus",
    "13": "Muu poikkeus (hulevesi, korkeusasema yms.)",
  },
  rakrek_kulthistmer: {
    "1": "Kansainvälinen merkittävyys",
    "2": "Valtakunnallinen merkittävyys",
    "3": "Maakunnallinen merkittävyys",
    "4": "Paikallinen merkittävyys",
    "5": "Vähäisiä arvoja",
  },
  rakrek_rakelinvaih: {
    "01": "suunnitteilla",
    "02": "rakenteilla",
    "03": "käytössä",
    "04": "käyttökiellossa/käyttökelvoton",
    "05": "tuhoutunut",
    "06": "purettu",
    "07": "yhdistetty tai jaettu",
    "08": "rakennelma korvattu rakennuksella",
    "99": "ei tietoa",
  },
  vtj_Rake_lammitystapa: {
    "01": "Vesikeskuslämmitys",
    "02": "Ilmakeskuslämmitys",
    "03": "Suora sähkölämmitys",
    "04": "Uunilämmitys",
    "05": "Ei kiinteää lämmityslaitetta",
  },
  rytj_Energialuokka: {
    "01": "A",
    "02": "B",
    "03": "C",
    "04": "D",
    "05": "E",
    "06": "F",
    "07": "G",
    "08": "Ei vaadittu",
  },
  rytj_Paloluokka: {
    "00": "P0",
    "01": "P1",
    "02": "P2",
    "03": "P3",
  },
  rytj_RakennelmanKayttotarkoitus: {
    "001": "Aallonmurtaja",
    "002": "Aita",
    "003": "Antenni",
    "004": "Asuntovaunu",
    "005": "Autosuoja",
    "006": "Autotalli",
    "007": "Ei tiedossa",
    "008": "Hyppyrimäki",
    "009": "Ikkuna",
    "010": "Infotaulu (jalankulkuopastaulu)",
    "011": "Jakokaappi",
    "012": "Jalasmökki",
    "013": "Jätekatos tai -aitaus",
    "014": "Jätevesijärjestelmä",
    "015": "Kasvihuone",
    "016": "Katos/pergola",
    "017": "Katsomo",
    "018": "Katumainostaulu",
    "019": "Kelluva rakennelma",
    "020": "Kierrätyspiste",
    "021": "Kioski",
    "022": "Kolmiopilari",
    "023": "Laituri",
    "024": "Lastauslaituri",
    "025": "Liikuteltava grillikioski",
    "026": "Lipputankoryhmä",
    "027": "Maakellari",
    "028": "Maalämpöpumppujärjestelmä",
    "029": "Mainoslaite",
    "030": "Markiisi",
    "031": "Masto",
    "032": "Muu käyttö",
    "033": "Muu rakennelma",
    "034": "Muu toimenpide",
    "035": "Muu vesirajalaite",
    "036": "Muuntamo",
    "037": "Muuri",
    "038": "Näkötorni",
    "039": "Odotuskatos",
    "040": "Opaste",
    "041": "Ovi",
    "042": "Parvekelasitus",
    "043": "Pihajärjestely",
    "044": "Piippu",
    "045": "Portti",
    "046": "Puhelinkioski",
    "047": "Pylväsbanderolli",
    "048": "Pylvästaulu",
    "049": "Pysäkkikatos",
    "050": "Pysäköintialue",
    "051": "Pyöreä mainospilari",
    "052": "Rantamuuri",
    "053": "Savupiippu",
    "054": "Siirtopuutarhamökki",
    "055": "Suurtaulu, sisältä valaistu",
    "056": "Suurtaulu, ulkoa valaistu",
    "057": "Taideteos",
    "058": "Taksikatos",
    "059": "Tuulivoimala",
    "060": "Ulkomainoslaite",
    "061": "Ulkotarjoilualue",
    "062": "Vaja",
    "063": "Valaisinpylväs",
    "064": "Varasto",
    "065": "Varastointialue",
    "066": "Viestintätorni",
    "067": "Yleisöteltta",
    "068": "Yleisö-WC",
    "069": "Varastointisäiliö",
    "070": "Aurinkopaneeli",
  },
  vtj_Rake_runkotapa: {
    "01": "Elementti",
    "02": "Paikalla tehty",
  },
  rytj_rakennuksentietomallinlaji: {
    "01": "Suunnittelumalli",
    "02": "Toteumamalli",
  },
  jhs_rakennus_1_20180712: {
    "01": "Asuinrakennukset",
    "011": "Pientalot",
    "0110": "Omakotitalot",
    "0111": "Paritalot",
    "0112": "Rivitalot",
    "012": "Kerrostalot",
    "0120": "Pienkerrostalot",
    "0121": "Asuinkerrostalot",
    "013": "Asuntolarakennukset",
    "0130": "Asuntolarakennukset",
    "014": "Erityisryhmien asuinrakennukset",
    "0140": "Erityisryhmien asuinrakennukset",
    "02": "Vapaa-ajan asuinrakennukset",
    "021": "Vapaa-ajan asuinrakennukset",
    "0210": "Ympärivuotiseen käyttöön soveltuvat vapaa-ajan asuinrakennukset",
    "0211": "Osavuotiseen käyttöön soveltuvat vapaa-ajan asuinrakennukset",
    "03": "Liikerakennukset",
    "031": "Myymälärakennukset",
    "0310": "Tukku- ja vähittäiskaupan myymälähallit",
    "0311": "Kauppakeskukset ja liike- ja tavaratalot",
    "0319": "Muut myymälärakennukset",
    "032": "Majoitusliikerakennukset",
    "0320": "Hotellit",
    "0321": "Motellit, hostellit ja vastaavat majoitusliikerakennukset",
    "0322": "Loma-, lepo- ja virkistyskodit",
    "0329": "Muut majoitusliikerakennukset",
    "033": "Ravintolarakennukset ja vastaavat liikerakennukset",
    "0330": "Ravintolarakennukset ja vastaavat liikerakennukset",
    "04": "Toimistorakennukset",
    "040": "Toimistorakennukset",
    "0400": "Toimistorakennukset",
    "05": "Liikenteen rakennukset",
    "051": "Liikenne- ja kuljetusalan rakennukset",
    "0510": "Asemarakennukset ja terminaalit",
    "0511": "Ammattiliikenteen kaluston suojarakennukset",
    "0512": "Ammattiliikenteen kaluston huoltorakennukset",
    "0513": "Pysäköintitalot ja -hallit",
    "0514": "Kulkuneuvojen katokset",
    "052": "Tieto- ja viestintätekniikan rakennukset",
    "0520": "Datakeskukset ja laitetilat",
    "0521": "Tietoliikenteen rakennukset",
    "059": "Muut liikenteen rakennukset",
    "0590": "Muut liikenteen rakennukset",
    "06": "Hoitoalan rakennukset",
    "061": "Terveydenhuoltorakennukset",
    "0610": "Terveys- ja hyvinvointikeskukset",
    "0611": "Keskussairaalat",
    "0612": "Erikoissairaalat ja laboratoriorakennukset",
    "0613": "Muut sairaalat",
    "0614": "Kuntoutuslaitokset",
    "0615": "Eläintenhoitorakennukset",
    "0619": "Muut terveydenhuoltorakennukset",
    "062": "Sosiaalipalvelurakennukset",
    "0620": "Laitospalvelujen rakennukset",
    "0621": "Avopalvelujen rakennukset",
    "063": "Vankilarakennukset",
    "0630": "Vankilarakennukset",
    "07": "Kokoontumisrakennukset",
    "071": "Kulttuurirakennukset",
    "0710": "Teatterit, musiikki- ja kongressitalot",
    "0711": "Elokuvateatterit",
    "0712": "Kirjastot ja arkistot",
    "0713": "Museot ja taidegalleriat",
    "0714": "Näyttely- ja messuhallit",
    "072": "Seura- ja kerhorakennukset",
    "0720": "Seura- ja kerhorakennukset",
    "073": "Uskonnollisten yhteisöjen rakennukset",
    "0730": "Uskonnonharjoittamisrakennukset",
    "0731": "Seurakuntatalot",
    "0739": "Muut uskonnollisten yhteisöjen rakennukset",
    "074": "Urheilu- ja liikuntarakennukset",
    "0740": "Jäähallit",
    "0741": "Uimahallit",
    "0742": "Monitoimihallit",
    "0743": "Urheilu- ja palloiluhallit",
    "0744": "Stadion- ja katsomorakennukset",
    "0749": "Muut urheilu- ja liikuntarakennukset",
    "079": "Muut kokoontumisrakennukset",
    "0790": "Muut kokoontumisrakennukset",
    "08": "Opetusrakennukset",
    "081": "Varhaiskasvatuksen rakennukset",
    "0810": "Lasten päiväkodit",
    "082": "Yleissivistävien oppilaitosten rakennukset",
    "0820": "Yleissivistävien oppilaitosten rakennukset",
    "083": "Ammatillisten oppilaitosten rakennukset",
    "0830": "Ammatillisten oppilaitosten rakennukset",
    "084": "Korkeakoulu- ja tutkimuslaitosrakennukset",
    "0840": "Korkeakoulurakennukset",
    "0841": "Tutkimuslaitosrakennukset",
    "089": "Muut opetusrakennukset",
    "0890": "Vapaan sivistystyön opetusrakennukset",
    "0891": "Järjestöjen, liittojen, työnantajien ja vastaavien opetusrakennukset",
    "09": "Teollisuuden ja kaivannaistoiminnan rakennukset",
    "091": "Teollisuuden tuotantorakennukset",
    "0910": "Yleiskäyttöiset teollisuushallit",
    "0911": "Raskaan teollisuuden tehdasrakennukset",
    "0912": "Elintarviketeollisuuden tuotantorakennukset",
    "0919": "Muut teollisuuden tuotantorakennukset",
    "092": "Teollisuus- ja pienteollisuustalot",
    "0920": "Teollisuus- ja pienteollisuustalot",
    "093": "Kaivannaistoiminnan rakennukset",
    "0930": "Metallimalmien käsittelyrakennukset",
    "0939": "Muut kaivannaistoiminnan rakennukset",
    "10": "Energiahuoltorakennukset",
    "101": "Energiantuotantorakennukset",
    "1010": "Sähköenergian tuotantorakennukset",
    "1011": "Lämpö- ja kylmäenergian tuotantorakennukset",
    "109": "Muut energiahuoltorakennukset",
    "1090": "Energiansiirtorakennukset",
    "1091": "Energianvarastointirakennukset",
    "11": "Yhdyskuntatekniikan rakennukset",
    "111": "Vesihuollon rakennukset",
    "1110": "Vedenotto-, vedenpuhdistus- ja vedenjakelurakennukset",
    "112": "Jätehuollon rakennukset",
    "1120": "Jätteenkeruu-, jätteenkäsittely- ja jätteenvarastointirakennukset",
    "113": "Materiaalien kierrätysrakennukset",
    "1130": "Materiaalien kierrätysrakennukset",
    "12": "Varastorakennukset",
    "121": "Varastorakennukset",
    "1210": "Lämmittämättömät varastot",
    "1211": "Lämpimät varastot",
    "1212": "Kylmä- ja pakastevarastot",
    "1213": "Muut olosuhteiltaan säädellyt varastot",
    "1214": "Logistiikkakeskukset ja muut monikäyttöiset varastorakennukset",
    "1215": "Varastokatokset",
    "13": "Pelastustoimen rakennukset",
    "131": "Pelastustoimen rakennukset",
    "1310": "Paloasemat",
    "1311": "Väestönsuojat",
    "1319": "Muut pelastustoimen rakennukset",
    "14": "Maatalousrakennukset ja eläinsuojat",
    "141": "Kotieläinrakennukset",
    "1410": "Lypsykarjarakennukset",
    "1411": "Lihakarjarakennukset",
    "1412": "Sikalat",
    "1413": "Lampolat ja vuohinavetat",
    "1414": "Hevostallit",
    "1415": "Siipikarjarakennukset",
    "1416": "Turkiseläinrakennukset",
    "1419": "Muut eläinsuojat",
    "149": "Muut maatalousrakennukset",
    "1490": "Kasvihuoneet",
    "1491": "Viljankuivaamot ja viljansäilytysrakennukset",
    "1492": "Maatalouden varastorakennukset",
    "1493": "Lantalat",
    "1499": "Muut maa-, metsä- ja kalatalouden rakennukset",
    "19": "Muut rakennukset",
    "191": "Muut rakennukset",
    "1910": "Saunarakennukset",
    "1911": "Talousrakennukset",
    "1912": "Majat ja tuvat",
    "1919": "Muualla luokittelemattomat rakennukset",
  },
  rytj_RakennuskohteenTyyppi: {
    "01": "rakennus",
    "02": "rakennelma",
  },
  rytj_RakennuskohteenTiedonlahde: {
    "01": "Rakennuslupa",
    "02": "Omistajan ilmoitus",
    "03": "Rakennusvalvonnan ilmoitus",
    "04": "Kunnan ilmoitus",
  },
  rytj_Omistajalaji: {
    "01": "yksityinen maatalousyrittäjä",
    "02": "muu yksityinen henkilö tai perikunta",
    "03": "asunto oy tai asunto-osuuskunta",
    "04": "kiinteistö oy",
    "05": "yksityinen yritys",
    "06": "valtio- tai kuntaenemmistöinen yritys",
    "07": "kunnan liikelaitos",
    "08": "valtion liikelaitos",
    "09": "pankki- tai vakuutuslaitos",
    "10": "kunta tai kuntainliitto",
    "11": "valtio",
    "12": "sosiaaliturvarahasto",
    "13": "uskonnollinen yhteisö, säätiö, puolue tai yhdistys",
    "14": "muu",
  },
  rytj_Omistuksenlaji: {
    "01": "varsinainen omistaja",
    "02": "omistajan edustaja",
  },
  rytj_RakennusvalvontaAsianTyyppi: {
    "01": "Rakentamisasia",
    "02": "Purkamisasia",
    "03": "Poikkeamisasia",
    "04": "Maisematyöasia",
  },
  rytj_Rakentamistoimenpide: {
    "01": "Uusi rakennus tai rakennelma",
    "02": "Laajentaminen",
    "03": "Uudelleen rakentamiseen verrattava muutostyö",
    "04": "Muu muutostyö",
    "05": "Purkaminen",
    "06": "Kaupunkikuvatoimenpide",
    "08": "Rakennuksen osittainen purkaminen",
  },
  rytj_PurkamisenSyy: {
    "01": "rakennuksen poisto",
    "02": "puretaan tuhoutunut rakennus",
    "03": "puretaan uudisrakentamisen vuoksi",
    "04": "puretaan muusta syystä",
  },
  rytj_sahkoenergianlahde: {
    "01": "Sähköliittymä",
    "02": "Vesivoima",
    "03": "Kaukolämpö",
    "04": "Aurinkosähkö",
    "05": "Dieselvoima",
    "06": "Sähköenergiavarasto",
    "99": "Muu",
  },
  rytj_sisaankaynti: {
    "01": "Pääsisäänkäynti porrashuoneeseen",
    "02": "Muu sisäänkäynti porrashuoneeseen",
    "03": "Sisäänkäynti huoneistoon",
    "04": "Pääsisäänkäynti yleisölle tai asiakkaille",
    "05": "Muu sisäänkäynti yleisölle tai asiakkaille",
    "06": "Tavaran toimitus tai nouto",
    "07": "Sisäänkäynti ajoneuvoille",
    "99": "Muu sisäänkäynti",
  },
  rytj_RY_TonttijakosuunnitelmanLaji: {
    "01": "Asemakaavan yhteydessä hyväksyttävä tonttijako",
    "02": "Erillinen tonttijako",
  },
  rytj_RY_TonttijakosuunnitelmanElinkaarentila: {
    "01": "Vireillä",
    "02": "Luonnos",
    "03": "Ehdotus",
    "04": "Hyväksytty",
    "05": "Voimassa",
    "06": "Kumottu osittain",
    "07": "Kumottu kokonaan",
    "08": "Kumoutunut osittain",
    "09": "Kumoutunut kokonaan",
  },
  rytj_talousvesi: {
    "01": "Verkostoliittymä",
    "02": "Oma kaivo",
    "0201": "Rengaskaivo",
    "0202": "Porakaivo",
    "0203": "Siiviläputkikaivo",
    "0204": "Pintavesikaivo",
    "03": "Kantovesi",
    "04": "Ei talousvettä",
  },
  rytj_VarusteenLaji: {
    "01": "Rakennuskohteen varuste",
    "02": "Rakennuksen varuste",
    "03": "Tilan varuste",
    "04": "Huoneiston varuste",
  },
  rytj_verkliit: {
    "01": "tietoliikennekaapeli",
    "02": "talousvesi",
    "03": "jätevesi",
    "04": "sähkö",
    "05": "kaukolämpö",
    "06": "kaasu",
    "07": "hulevesi",
    "08": "kaukojäähdytys",
    "09": "sammutusvesi",
  },
  rytj_jatevesienkasittely: {
    "01": "Verkostoliittymä",
    "02": "Jätevesisäiliö",
    "03": "Maapuhdistamo",
    "04": "Laitepuhdistamo",
    "05": "Harmaavesipuhdistamo",
    "06": "Ei tarvetta jätevesien käsittelylle",
    "99": "Muu käsittely",
  },
};
//export default rytjCodes

export const getRytjCodeValue = (codegroup: {[code: string]: {[lang: string]: string}}|{[code: string]: string}|any, code?: string, language: "fi"|"en"|"en-US"|"sv" = "fi", defaultValue?: string) => {
  if (!codegroup || !code || !codegroup[code]) return defaultValue||code;
  if (typeof codegroup[code] === "string") return codegroup[code];
  const codeWithLanguage = codegroup[code] as {[language: string]: string};
 
  if (language === "en-US") language = "en";
  return codeWithLanguage[language] || codeWithLanguage["fi"]||defaultValue||code;
}
// export const getRytjCodes = (codegroupname: keyof typeof rytjCodes) => {
//   return Object.keys(rytjCodes[codegroupname]||{});
// }
