import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, Icon, TextInput, Notification, LoadingSpinner } from 'suomifi-ui-components';
import { Form, redirect, useActionData, useLocation, useNavigation } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://paatoseditori.azurewebsites.net">
        Päätöseditori
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

/*
interface IAction {
  type: "clear"|"";
  value?: string|null;
}
interface IState {
  error: string|null;
}
const reducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case 'clear': 
      return {
        error: null
      };
    default: 
      return {
        error: state.error
      }
    }
}
const [state, setState] = useReducer(reducer, { error: null });
*/

export default function SignIn() {

  const location = useLocation();
  const actionData = useActionData() as IActionData | undefined;

  const navigation = useNavigation();
  const loading = navigation.state === "loading";
  const submitting = navigation.state === "submitting";

  const [hideErrorDateTime, setHideErrorDateTime] = useState<Date>(new Date()); // TODO figure out a better way to do this...
  //const [loading, setLoading] = useState<boolean>(false);


  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >


          <Icon icon="login" color='' />
          <Typography component="h1" variant="h5">
            Kirjautuminen
          </Typography>
          {/* <Box component="form" method="post" action={location.pathname} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} width={320}> */}
          <Form method='post'>
            <TextInput className='input--custom'
              required
              fullWidth
              id="email"
              label="Sähköposti"
              name="email"
              autoComplete="email"
              autoFocus labelText="Sähköposti" />
            <TextInput className='input--custom'
              required
              fullWidth
              name="password"
              label="Salasana"
              labelText="Salasana"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Muista minut"
                /> */}
            <br />
            <Button
              disabled = {submitting} 
              type="submit"
              fullWidth
              variant="default"
              
            >
              Kirjaudu sisään
            </Button>

          </Form>
          {/* </Box> */}

          <Box height={60} mt={3}>
          { submitting && <LoadingSpinner
            status="loading"
            text="Kirjautuminen käynnissä..."
            textAlign="right"
            variant="normal"
          />
          }
          <Grid container maxWidth="xs">
              <Grid item>
                { actionData && actionData.datetime > hideErrorDateTime && actionData.error && <Notification
                  closeText="Sulje"
                  headingText="Kirjautuminen epäonnistui"
                  status="error"
                  onCloseButtonClick={() => { setHideErrorDateTime(new Date()) }}
                >
                  {actionData.error}
                </Notification>
                }
              </Grid>

            </Grid>
          </Box>
          
        </Box>
        
        {/* <Copyright sx={{ mt: 0, mb: 4 }} /> */}
        
        
      </Container>
      
    </ThemeProvider>
  );
}

interface IActionData {
  error?: string,
  datetime: Date
}


export const loginPostAction  = async ({ request }: any) => {
  const data = Object.fromEntries(await request.formData());

  //const username = data.get("username");
  //const password = data.get("password");

  try {
    //const delay = (ms:any) => new Promise(res => setTimeout(res, ms));
    //await delay(3000);
    const result = await axios.post<any>("/login", data);//.then((res) => {

    if (result.data.error === undefined) {
      // session storage?
      const searchParams = new URLSearchParams(document.location.search)
      const redirectpath = searchParams.get("redirect");

      return redirect(redirectpath?.startsWith("/") ? redirectpath : "/");
    }
  }
  catch (err: any) {
    return { error: err.message, datetime: new Date() } as IActionData
  }

  return { error: "Not implemented!" } as IActionData;
}