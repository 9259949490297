import { Card, CardActions, CardContent, Grid, Paper } from "@mui/material";
import React, { useContext } from "react";
import { Link, redirect, useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import { Button, Heading, Label, LoadingSpinner, Text } from "suomifi-ui-components";
import { RAKAlueidenkayttopaatos, RAKLupapaatos, RAKPaatos, RytjAPI } from "../api/rytj/rytj-api";
import { UserContext } from "../App";
import AreaDecisionView from "../components/area-decision/AreaDecisionView";
import PermissionDecisionView from "../components/permission-decision/PermissionDecisionView";
import DecisionBasicInfo from "./DecisionBasicInfo";

interface Props {
  //decisions: RAKPaatos[],
  //id: string,
  //loading: boolean
}
const DecisionView: React.FC<Props> = (props) => {
  const loaderData = useLoaderData() as {paatos?: RAKPaatos, alueidenkayttopaatos?: RAKAlueidenkayttopaatos, lupapaatos?: RAKLupapaatos};
  
  const areaDecision = loaderData?.alueidenkayttopaatos;
  const permissionDecision = loaderData?.lupapaatos;
  const decision = loaderData?.paatos; 

  let jsonLink: string|undefined;
  if (areaDecision?.ylaluokka?.versiointitiedot?.identiteettitunnus) {
    jsonLink = areaDecision.ylaluokka.versiointitiedot.identiteettitunnus + "/alueidenkayttopaatos?select=alueidenkayttopaatoksenlaatija,alueidenkayttopaatoksentunnus,lahtotietoaineisto,ylaluokka.versiointitiedot,ylaluokka.liittyvaasiakirja";
  }
  else if (permissionDecision?.ylaluokka?.versiointitiedot?.identiteettitunnus) {
    jsonLink = permissionDecision.ylaluokka.versiointitiedot.identiteettitunnus + "/lupapaatos?select=ylaluokka.versiointitiedot,ylaluokka.liittyvaasiakirja.versiointitiedot,lupamaarays,lupahakemus,myonnetytpoikkeamiset,lupatunnus";  
  }
  else if (decision?.versiointitiedot?.identiteettitunnus) {
    jsonLink = decision?.versiointitiedot?.identiteettitunnus + "/paatos?select=versiointitiedot,liittyvaasiakirja.versiointitiedot";
  }

  if (jsonLink) {
    jsonLink = "https://kaavatietomalli.azurewebsites.net/api/paatos/" + encodeURI(jsonLink);
  }
  // const isLoggedIn = sessionStorage.getItem("username") != null;
  //const { isLoggedIn } = useContext(UserContext);
  const user = useContext(UserContext);
  const isLoggedIn = user?.isLoggedIn;

  const navigate = useNavigate();
  const navigation = useNavigation();
  if (navigation.state === "loading") {
    return <Card>
      <CardContent>
        <LoadingSpinner
          status="loading"
          text="Lataa..."
          textAlign="right"
          variant="normal"
        />
      </CardContent>
    </Card>
  }

  const deleteDecision = async () => {

    const rytjApi = new RytjAPI({ baseUrl:"/rytj-api" });
    if (decision?.versiointitiedot?.identiteettitunnus) {
      const result = await rytjApi.paatos.paatosDelete(decision.versiointitiedot.identiteettitunnus);
 
      if (result.data) {
        navigate("/");
      }
    }
    
  }

  return <>
    {decision ?
      <Grid container rowSpacing={2}>
        <Grid item className="no-print">
          <Link to="/">
            <Button icon='arrowLeft' variant='secondary'>Etusivu</Button>
          </Link>
        </Grid>
        <Grid item className="no-print" style={{ flexGrow: "1", flexDirection: "row", textAlign: "center" }}>
          <Button icon="print" variant='secondary' onClick={() => {window.print();return false;}}>Tulosta</Button>
          &nbsp;
          &nbsp;
          
          <Link to={jsonLink||""} 
            type="application/json" rel="import" target="_blank">
            <Button disabled={!jsonLink} icon="linkExternal" variant='secondary'>JSON</Button>
          </Link>
        </Grid>
        <Grid item className="no-print">
          <Button icon="remove" disabled={!isLoggedIn} variant='secondary' onClick={deleteDecision}>Poista</Button>&nbsp;
          <Link to={"/decision/edit/" + encodeURI(decision.versiointitiedot?.identiteettitunnus||"")}>
            <Button icon="edit" disabled={!isLoggedIn} variant='default'>Muokkaa</Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Paper className="mainPaper" sx={{ minHeight: '80vh', marginBottom: "40px" }}>
            <Grid container spacing={10}>
              <Grid item sm={12} md={6} >
                <DecisionBasicInfo decision={decision}></DecisionBasicInfo>
              </Grid>
              {/* <Grid item sm={false} md={1} sx={{borderLeft: "1px solid #ccc"}}></Grid> */}
              
              <Grid item sm={12} md={6} className="page-break-before">
                { areaDecision && 
                <AreaDecisionView areaDecision={areaDecision}></AreaDecisionView>
                }
                { permissionDecision && 
                <PermissionDecisionView permissionDecision={permissionDecision}></PermissionDecisionView>
                }
              </Grid>             
            </Grid>         

          </Paper>
        </Grid>
      </Grid>
      :
<p>not found!</p>
    }
  </>

  //const loading = props.loading;
  // return loading ?
  //   <Card>
  //     <CardContent>
  //       <LoadingSpinner
  //         status="loading"
  //         text="Noutaa päätöksiä..."
  //         textAlign="right"
  //         variant="normal"
  //       />
  //     </CardContent>
  //   </Card>
  //   :
  //   <React.Fragment>
  //       { decision ?
  //       <Card>
  //       <CardContent>
  //         <Heading variant='h3'>{decision.paatoksennimi}</Heading>
  //         <Text variant='lead'>{decision.paatosteksti}</Text>
  //         {decision.paatoksentekija2}

  //         {decision.paatoksenantopaivamaara}
  //         {decision.paatoksentila}
  //         {decision.paatoksentulos}
  //       </CardContent>
  //       <CardActions>
  //         <Link to="/">
  //           <Button variant='link'>Sulje</Button>
  //         </Link>
  //         <Link to="./edit">
  //           <Button variant='link'>Muokkaa</Button>
  //         </Link>
  //       </CardActions>
  //     </Card>
  //       : 
  //       <p>not found!</p>
  //       }
  //   </React.Fragment>

};
export default DecisionView;

export async function decisionNewLoader(params: any): Promise<{ alueidenkayttopaatos?: RAKAlueidenkayttopaatos, lupapaatos?: RAKLupapaatos, paatos?: RAKPaatos}> {
  const now = new Date().toISOString();
  
  return { 
    paatos: { 
      paatoksennimi: "", 
      paatoksentekija2: sessionStorage.getItem("user") || "",
      paatoksenantopaivamaara: now, 
      paatoksenlainvoimaisuuspaivamaara: now, 
      julkaisemisenpaivamaara: now
    } as RAKPaatos};
}

export async function decisionLoader(params: any): Promise<{ alueidenkayttopaatos?: RAKAlueidenkayttopaatos, lupapaatos?: RAKLupapaatos, paatos?: RAKPaatos}> {
  const api = new RytjAPI();//{baseUrl:"/rytj-api"});
  
  const lupapaatos = await api.paatos.lupapaatosGetById(params.id, { select: "ylaluokka.versiointitiedot,ylaluokka.liittyvaasiakirja.versiointitiedot,lupamaarays,lupahakemus,myonnetytpoikkeamiset,lupatunnus", browserCache: 5 });
  if (lupapaatos?.data?.lupatunnus) {
    return { lupapaatos: lupapaatos.data, paatos: lupapaatos.data.ylaluokka };
  }
  
  const alueidenkayttopaatos = await api.paatos.alueidenkayttopaatosGetById(params.id, { select: "ylaluokka,ylaluokka.versiointitiedot,ylaluokka.liittyvaasiakirja.versiointitiedot,lahtotietoaineisto,alueidenkayttopaatoksenlaatija,alueidenkayttopaatoksentunnus", browserCache: 5 });
  if (alueidenkayttopaatos?.data?.alueidenkayttopaatoksentunnus) {
    return { alueidenkayttopaatos: alueidenkayttopaatos.data, paatos: alueidenkayttopaatos.data.ylaluokka };
  }

  

  const result = await api.paatos.paatosGetById(params.id, { select: 'versiointitiedot,iittyvaasiakirja.versiointitiedot', browserCache: 5 });
  return { paatos: result.data, /*lupapaatos: {},*/ alueidenkayttopaatos: {} };
}
